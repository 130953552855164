import React, { useContext, useEffect, useState } from "react";
import Logo from "../../assets/images/logo.svg";
import { Button, IconButton, Tooltip, Zoom, List, ListItemButton, ListItemIcon, ListItemText, Divider, Collapse } from '@mui/material';
import { IconAdd, IconMenuHide2, IconMenuShow2, IconTutorials,IconPerson,IconBookDemo, IconAnalytic, IconFolder, IconAnchorText, IconCriticalIssues, IconUser, IconExpand, IconCollapse, IconLogout, IconDashboard } from "../../utils/Icon";
import { COLOR_CODES } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import { AppContext, defaultAppcontext } from "../../context/appContext";
import { CreateProject } from "../CreateProject/CreateProject";
import { getAllSearchedTargetDomain } from "../commonHelper";

import "../../stylesheet/global.scss"
import "./sidebar.scss";

export const SideBar = ({ }) => {
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [showCreateProject, setShowCreateProject] = useState(false);
    const navigate = useNavigate();
    const { appContext, setAppContext } = useContext(AppContext);

    useEffect(() => {
        if (!appContext.user.username) {
            setAppContext({ ...appContext, showSideBar: false, isLoggedIn: false })
        }
    }, [])

    return (
        appContext.showSideBar && appContext.isLoggedIn && <aside
            className={`sidebar ${sideBarOpen ? 'expanded' : 'collapsed'}`}>
            <div
                onClick={setSideBarOpen.bind(null, !sideBarOpen)}
                className="menu">
                {sideBarOpen ? <IconMenuHide2 color="white" /> : <IconMenuShow2 color="white" />}
            </div>

            <div className="child top">

                <div
                    className="logo-menu"
                // onClick={navigate.bind(null, "/home")}
                >
                    <img src={Logo} alt="" />
                    {sideBarOpen && <span onClick={navigate.bind(null, "/home")}>Link Validator</span>}
                </div>

                {sideBarOpen ?
                    <Button
                        variant="contained"
                        color="primary"
                        size={sideBarOpen ? "large" : "small"}
                        sx={{ bgcolor: "#24B7C7" }}
                        startIcon={<IconAdd />}
                        onClick={setShowCreateProject.bind(null, true)}
                    >create project</Button>
                    :
                    <Tooltip
                        title="Create Project"
                        TransitionComponent={Zoom}>
                        <IconButton
                            onClick={setShowCreateProject.bind(null, true)}
                            style={{ background: "#24B7C7", borderRadius: "4px", color: "white" }}>
                            <IconAdd />
                        </IconButton>
                    </Tooltip >
                }

                <Divider variant="middle" sx={{ margin: "1rem 0", bgcolor: COLOR_CODES.Branded, width: "90%" }} />

                <List
                    sx={{ overflow: "auto", width: "100%" }}
                    component="nav"
                    disablePadding>
                    {
                        appContext.projectsList.map((proj, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        background: (appContext.projectName == proj) ? "#13374A" : "#0F172A",
                                    }}>
                                    <Tooltip
                                        title={proj}
                                        TransitionComponent={Zoom}>
                                        <ListItemButton
                                            sx={{ width: "100%" }}
                                            onClick={setExpandedIndex.bind(null, expandedIndex == i ? -1 : i)}>
                                            <div style={{
                                                display: "flex",
                                                gap: "2ch",
                                                alignItems: "center",
                                                maxWidth: "100%",
                                                width: "100%"
                                            }}>
                                                <div style={{ width: "5%" }}><IconFolder color="white" height="20" weight="20" /></div>
                                                {sideBarOpen && <div style={{ width: "80%", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    <p style={{ margin: 0, fontSize: "15px" }}>{proj}</p></div>}
                                                <div style={{ width: "10%" }}>{expandedIndex == i ? <IconCollapse /> : <IconExpand />}</div>
                                            </div>
                                        </ListItemButton>
                                    </Tooltip>

                                    <Collapse in={expandedIndex == i ? true : false} timeout="auto" unmountOnExit>
                                        <List component="div" sx={{ pl: 2 }} id="hello">
                                            <Tooltip
                                                title={"Dashboard"}
                                                TransitionComponent={Zoom}>
                                                <ListItemButton
                                                className="icon-sidebar"
                                                    onClick={navigate.bind(null, `/${appContext.projectsList.indexOf(proj)}/dashboard`)}>
                                                    <ListItemIcon> <IconDashboard color={"white"} /></ListItemIcon>
                                                    {sideBarOpen && <ListItemText primary="Dashboard" primaryTypographyProps={{ fontSize: "13px" }} />}
                                                </ListItemButton>
                                            </Tooltip>

                                            <Tooltip
                                                title={"Anchor Text Profiles"}
                                                 TransitionComponent={Zoom}>
                                                <ListItemButton
                                                className="icon-sidebar"
                                                    onClick={navigate.bind(null, `/${appContext.projectsList.indexOf(proj)}/anchortext`)}>
                                                    <ListItemIcon> <IconAnchorText /> </ListItemIcon>
                                                    {sideBarOpen && <ListItemText primary="Anchor Text Profiles" primaryTypographyProps={{ fontSize: "13px" }} />}
                                                </ListItemButton>
                                            </Tooltip>

                                            <Tooltip
                                                title={"Analytics"}
                                                TransitionComponent={Zoom}>
                                                <ListItemButton
                                                className="icon-sidebar"
                                                    onClick={navigate.bind(null, `/${appContext.projectsList.indexOf(proj)}/analytics`)}>
                                                    <ListItemIcon> <IconAnalytic />  </ListItemIcon>
                                                    {sideBarOpen && <ListItemText primary="Analytics" primaryTypographyProps={{ fontSize: "13px" }} />}
                                                </ListItemButton>
                                            </Tooltip>


                                            <Tooltip
                                                title={"Critical Issues"}
                                                TransitionComponent={Zoom}>
                                                <ListItemButton
                                                className="icon-sidebar"
                                                    onClick={navigate.bind(null, `/${appContext.projectsList.indexOf(proj)}/issues`)}>
                                                    <ListItemIcon>
                                                        <IconCriticalIssues />
                                                    </ListItemIcon>
                                                    {sideBarOpen && <ListItemText
                                                        primary="Critical Issues"
                                                        primaryTypographyProps={{ fontSize: "13px" }}
                                                        style={{ fontSize: "13px !important" }} />}
                                                </ListItemButton>
                                            </Tooltip>
                                        </List>
                                    </Collapse>

                                </div>
                            )
                        })
                    }
                </List>
            </div>

            <Divider variant="middle" sx={{ margin: "1rem 0", bgcolor: COLOR_CODES.Branded, width: "90%" }} />

            <div className="bottom-sidebar">
            <ListItemButton
                sx={{ width: "100%" }}
                onClick={navigate.bind(null, `tutorials`)}
                >
                <div style={{
                    display: "flex",
                    gap: "2ch",
                    alignItems: "center",
                    maxWidth: "100%",
                    width: "100%"
                }}>
                    <div style={{ width: "5%" }}><IconTutorials color="white" height="20" weight="20" /></div>
                    {sideBarOpen && <div style={{ width: "80%", overflow: "hidden", textOverflow: "ellipsis" }}>
                        <p style={{ margin: 0, fontSize: "15px" }}>Tutorials</p></div>}
                    <div style={{ width: "10%" }}></div>
                </div>
            </ListItemButton>
            <ListItemButton
                sx={{ width: "100%" }}
                onClick={() => {
                    window.open('https://cal.com/theblueprints', '_blank');
                }}>
                <div style={{
                    display: "flex",
                    gap: "2ch",
                    alignItems: "center",
                    maxWidth: "100%",
                    width: "100%"
                }}>
                    <div style={{ width: "5%" }}><IconBookDemo color="white" height="20" weight="20" /></div>
                    {sideBarOpen && <div style={{ width: "80%", overflow: "hidden", textOverflow: "ellipsis" }}>
                        <p style={{ margin: 0, fontSize: "15px" }}>Book a Demo</p></div>}
                    <div style={{ width: "10%" }}></div>
                </div>
            </ListItemButton>
            <div className="user-cntnt" onClick={navigate.bind(null, "/profile")}>
            <div><IconPerson color="white" height="20" weight="20" />
            {sideBarOpen && <h3  style={{ margin: "0", padding: "0" ,overflow: "hidden", textOverflow: "ellipsis", fontSize: "15px", fontWeight: "normal"}}>{appContext.userDetails.fname} &nbsp;
                        {sideBarOpen && appContext.userDetails.lname}</h3>}
            </div>
           
            <ListItemButton
                sx={{ width: "100%" }}
                onClick={() => {
                                setAppContext(defaultAppcontext);
                                localStorage.removeItem("context");
                                navigate("/login")
                            }}>
                <div>
                    
                    
                    {sideBarOpen && <div style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
                    <IconButton
                            onClick={() => {
                                setAppContext(defaultAppcontext);
                                localStorage.removeItem("context");
                                navigate("/login")
                            }}
                            sx={{ bgcolor: "#ed5959", borderRadius: "4px", color: "white" }}>
                            <IconLogout />
                        </IconButton></div>}
                    <div style={{ width: "10%" }}></div>
                </div>
            </ListItemButton>
            </div>
           
            </div>

            {/* <div className="child bottom">
                    <a href='https://cal.com/theblueprints' target='_blank' className='btn-book-demo'>Book a Demo</a>
                {sideBarOpen && <li className="flex-column" style={{ justifyContent: "flex-start", alignItems: "center", gap: "0" }}>
                    <h5 style={{ color: "gray", margin: "0", padding: "0", wordWrap: "anywhere" }}>Welcome</h5>
                    <h3 style={{ margin: "0", padding: "0" }}>{appContext.userDetails.fname} &nbsp;
                        {sideBarOpen && appContext.userDetails.lname}</h3>
                </li>}

                <div style={{ width: "180px"}} className={sideBarOpen ? "flex-row" : "flex-column"}>
                    <Tooltip
                        title={appContext.userDetails.fname + " " + appContext.userDetails.lname}
                        TransitionComponent={Zoom}>
                        <IconButton
                            onClick={navigate.bind(null, "/profile")}
                            sx={{ bgcolor: "#24B7C7", borderRadius: "4px", color: "white" }}>
                            <IconUser color="white" height="20" width="20" />
                        </IconButton>
                    </Tooltip >

                    <Tooltip
                        title="Log Out"
                        TransitionComponent={Zoom}>
                        <IconButton
                            onClick={() => {
                                setAppContext(defaultAppcontext);
                                localStorage.removeItem("context");
                                navigate("/login")
                            }}
                            sx={{ bgcolor: "#ed5959", borderRadius: "4px", color: "white" }}>
                            <IconLogout />
                        </IconButton>
                    </Tooltip >
                </div>

            </div> */}
            {showCreateProject
                && <CreateProject isOpen={showCreateProject} onClose={setShowCreateProject} type="create" />}
        </aside>
    );
}


export default SideBar;