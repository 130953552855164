import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconQuotes } from "../../utils/Icon";

import logo from "../../assets/images/logo.svg";
import BgImage from "../../assets/images/loginBg.svg";
import profile from "../../assets/images/avtarSignup.svg";
import loader from "../../assets/images/loader.gif";
import loaderBlack from "../../assets/images/loader-black.gif";
import './Register.scss';

import { AccountContext } from "./Account";
import { AppContext, defaultAppcontext } from "../../context/appContext";

import UserPool from "../../UserPool";
import { getAllSearchedTargetDomain, getUserDetails } from "../commonHelper";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [showNewPassword, setshowNewPassword] = useState(false);
  const [verificationCode, setverificationCode] = useState("");
  const [isForgetPassword, setForgetPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { authenticate, getSession, resetPassword, confirmPassword } = useContext(AccountContext);
  const { appContext, setAppContext } = useContext(AppContext);

  useEffect(() => {
    localStorage.clear();
    setAppContext(() => {
      let a = {
        ...appContext,
        showBreadCrumbs: false,
        showSideBar: false,
        pageName: "login",
        isLoggedIn: false,
        projectsList: []
      }
      try {
        localStorage.setItem("context", JSON.stringify(a));
      } catch (error) {
      }
      return a;
    });
  }, [])

  const forgetPassword = async (e) => {
    e.preventDefault();
    let isOtpSent = resetPassword(username);
    if (isOtpSent) {
      setshowNewPassword(true);
    }
    if (newpassword) {
      confirmPassword(username, verificationCode, newpassword);
    }
  }


  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await authenticate(username, password);
      await getSession();
      let user = UserPool.getCurrentUser();
      let [userDetails, projectsList] = await Promise.all([
        getUserDetails(username),
        getAllSearchedTargetDomain(user.username)
      ]);
      const context = {
        ...appContext,
        user: user,
        userDetails: userDetails.body[0],
        projectsList: projectsList,
        showSideBar: true,
        showBreadCrumbs: true,
        isLoggedIn: true
      };
      setAppContext(() => {
        try {
          localStorage.setItem("context", JSON.stringify(context));
        } catch (error) {
        }
        setLoading(false);
        return context;
      })

      if (projectsList.length) {
        navigate("/0/dashboard");
      } else {
        navigate("/home")
      }

    } catch (err) {
      setLoading(false);
      var errIntext = err.toString();
      if (errIntext.includes("NotAuthorizedException")) {
        alert("Incorrect Email or password, please check");
      }
      if (errIntext.includes("UsernameExistsException")) {
        alert("User Already Exists, please go to login");
      }
    }
  };


  return (
    <div className="register login">
      <div className="content" style={{ "backgroundImage": `url(${BgImage})` }}>
        <div className="logo">
          <img className=" w-16" src={logo} alt="" />
          <span className="">Link Validator</span>
        </div>
        <div className="content-details">
          <span className="icon-quote"><IconQuotes /></span>
          <h2>
            We’ve invested a lot of resources in building links for our clients and we don’t want to spend more time checking if there are any link drops or glitches in those links. Link Validator keeps track of all backlinks with a click of a button.
          </h2>
          <div className="review-details">
            <img src={profile} alt="" />
            <div className="review-name">
              <p className="name">Alex Shute</p>
              <span className="desig">CEO, <a href="https://hummingbrd.co/" target='_blank'>Hummingbrd SEO</a></span>
            </div>
          </div>
        </div>
      </div>

      <div className="form-register mt-5">
        {
          isForgetPassword ?
            <form onSubmit={forgetPassword} className="mx-auto">
              <div>
                <h2 className="form-heading">
                  Reset Password</h2>
                <div className="input-group">
                  <label>
                    Email</label> <input type="text" value={username}
                      onChange={(e) => setUsername(e.target.value)} placeholder="you@gmail.com" />
                </div>
                <div className="input-group">
                  <label>Verifcation Code</label> <input type="text" placeholder="00000" value={verificationCode}
                    onChange={(e) => setverificationCode(e.target.value)} />
                </div>
                {
                  showNewPassword ? <div className="input-group">
                    <label>New Password</label> <input type="password" placeholder="you@gmail.com" value={newpassword}
                      onChange={(e) => setNewPassword(e.target.value)} />
                  </div> : null
                }
              </div>
              <button type="submit" className="mb-5">{newpassword ? 'Reset' : 'Send OTP'}</button>
            </form>
            :
            <form onSubmit={onSubmit} className="mx-auto">
              <div>
                <h2 className="form-heading">
                  Login</h2>
                <div className="input-group">
                  <label>
                    Email</label> <input type="text" value={username}
                      onChange={(e) => setUsername(e.target.value)} placeholder="you@gmail.com" />
                </div>
                <div className="input-group">
                  <label> Password</label> <input type="password" placeholder="password" value={password}
                    onChange={(e) => setPassword(e.target.value)} />
                </div>
                <a href="#" className="forget-pass" onClick={() => setForgetPassword(true)}>Forgot password ?</a>
                <button type="submit" className="mb-5">Login</button>
                <p className="signup-line"><span>Don’t have an account?
                  <a href="https://linkvalidator.io/pricing/"> Create one </a></span>
                </p>
              </div>
            </form>
        }
        {loading && <div className="loader">
          <img src={loaderBlack} height={100} width={100} />
        </div>}
      </div>
    </div>
  );
};

export default Login;
