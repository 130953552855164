import React from "react";
import loader from "../../assets/images/loader.gif"

export const Loader = (props) => {
    return (
        <div className="loader-custom">
            <img src={loader} alt="loading..." />
            <div className="loader-text"><p>Good things take time...</p><p>Hold on while we brew your data!</p></div>
        </div>
    );
}