import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { createContext } from 'react';
import UserPool from '../../UserPool';

const AccountContext = createContext();

const Account = (props) => {
  const getSession = async () => {
    await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
            localStorage.setItem('AUTH_TOKEN', session.getIdToken().getJwtToken())
          }
        });
      } else {
        reject();
      }
    });
  };

  const authenticate = async (Username, Password) => {
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          // console.log('login success', result);
          resolve(result);
        },
        onFailure: (err) => {
          console.log('login failure', err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          // console.log('new password required', data);
          resolve(data);
        },
      });
    });
  };

  const resetPassword = async (username) => {
    // const poolData = { UserPoolId: xxxx, ClientId: xxxx };
    // userPool is const userPool = new AWSCognito.CognitoUserPool(poolData);

    // setup cognitoUser first
    let cognitoUser = new CognitoUser({
      Username: username,
      Pool: UserPool
    });

    // call forgotPassword on cognitoUser
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        // console.log('call result: ' + result);
        return true;
      },
      onFailure: function (err) {
        alert(err);
      },
      // inputVerificationCode() { // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
      //     var verificationCode = prompt('Please input verification code ', '');
      //     var newPassword = prompt('Enter new password ', '');
      //     cognitoUser.confirmPassword(verificationCode, newPassword, this);
      // }
    });
  }

  // confirmPassword can be separately built out as follows...  
  const confirmPassword = async (username, verificationCode, newPassword) => {
    let cognitoUser = new CognitoUser({
      Username: username,
      Pool: UserPool
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve();
          window.location.href = '/login';
        },
      });
    });
  }

  const logout = () => {
    const user = UserPool.getCurrentUser();
    // console.log("myusername details",user)
    if (user) {
      user.username = '';
      user.signOut();
    }
    localStorage.clear();
    window.location.href = '/login';
  };

  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout, resetPassword, confirmPassword }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
