export const BASE_URL = 'https://gnur5vtyce.execute-api.us-east-1.amazonaws.com';

export const CRAWL_BLOCKED = 'Page Crawling Blocked, Please Check Manually';

export const CRAWL_BLOCKED_TIP = "Currently being crawled - will be updated in 24 hours";

export const LINK_NOT_FOUND = 'Link Not Found';

export const TYPE_OF_USERS = { 'GUEST': 'GUEST', 'PAID': 'PAID' };


export const COLOR_CODES = {
    'Branded': '#24B7C7',
    'URL': '#A155B9',
    'Exact Match': '#34D2A3',
    'Miscellaneous': '#F26797',
    'Partial Match': '#FFCBCB',
    'Images': '#DBB1EA',
    "Un Categorized": "#F5E642"
};

export const ISSUE_TITLES = {
    lnf: "Links not found",
    lanf: "Links as Nofollow",
    lni: "Links not Indexed",
    lmc: "Links with Missing in Cache",
    lsp: "Links with sponsored post",
    lwf: "Links with Write For Us"
}


export const NOT_GOOD_TARGET_URLS = ["page not found", "site error"]
export const ERROR_TEXTS = {
    "page not found": "PNF",
    "site error": "SE",
    "link not found": "LNF",
    "read time out error": "RTE"
}