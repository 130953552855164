import React, { useContext, useEffect, useState } from 'react';
import loader from '../../assets/images/loader.gif';
import "./ProfileDetails.scss";
import subscriptionImg from "../../assets/images/subscription.svg";
import { updateUserDetails } from '../commonHelper';
import { AppContext } from '../../context/appContext';
import "../../stylesheet/global.scss";
import { Button, TextField, FormControl, Card } from '@mui/material';

const ProfileDetails = (props) => {
  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [bName, setBName] = useState('');
  const [orderID, setOrderID] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [subscriptionData, setsubscriptionData] = useState({
    expiry_date: '',
    type: ''
  });

  const { appContext, setAppContext, reloadContext } = useContext(AppContext);
  useEffect(() => {
    setAppContext({
      ...appContext,
      pageName: "profile",
      projectName: null
    })

    setFName(appContext.userDetails.fname);
    setLName(appContext.userDetails.lname);
    setBName(appContext.userDetails.business_name);
    setEmail(appContext.user.username)
    setOrderID(appContext.userDetails.order_id);

    setsubscriptionData({
      'expiry_date': appContext.userDetails.expiry_date,
      'type': appContext.userDetails.type,
      'subs_type': appContext.userDetails.subscription_name
    });
  }, []);



  const onSubmit = (e) => {
    e.preventDefault();
    updateUserDetails({
      "username": email,
      "fname": fName,
      "lname": lName,
      "order_id": orderID
    })
      .then(_ => {
        reloadContext()
      })
      .catch(console.log);
  }

  return (
    loading ? <div className="loader-custom"><img src={loader} alt="loading..." /></div> :
      <div className='flex-column start width-100'>

        <h3 style={{ borderBottom: "1px solid gray", width: "50%" }}>Profile Details</h3>

        <form onSubmit={onSubmit} className="cstm-overflw flex-column start width-50">
          <div className='flex' style={{ gap: "1rem", padding: "10px 0 0 0", height: "auto" }}>
            <FormControl
              fullWidth>
              <TextField
                autoFocus
                label="First name"
                variant="outlined"
                value={fName}
                onChange={e => setFName(e.target.value)} />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                label="Last name"
                variant="outlined"
                value={lName}
                onChange={e => setLName(e.target.value)} />
            </FormControl>
          </div>

          <FormControl fullWidth><TextField
            label="Email"
            variant="outlined"
            disabled
            value={email}
            onChange={e => setEmail(e.target.value)} />
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label="Business name"
              variant="outlined"
              value={bName}
              onChange={e => setBName(e.target.value)} /></FormControl>

          <Button
            variant='contained'
            fullWidth
            sx={{ bgcolor: "#24B7C7" }}
            type="submit"
          >
            Save
          </Button>
        </form>



        <div className='cstm-overflw flex-column start width-100'>
          <h3 style={{ borderBottom: "1px solid gray", width: "50%" }} >Subscription Details</h3>
          <Card className='flex start width-50' sx={{ height: "auto", alignItems: "center" }}>
            <div className='subscription-icon'>
              <img src={subscriptionImg} alt="subscription" />
            </div>
            <div>
              <p className='mb-0 text-gray2'>{subscriptionData.subs_type}, Expires: {subscriptionData.expiry_date} </p>
              {/* <span className='text-gray2'><a href="https://linkvalidator.io/pricing/" target="_blank" className='text-dark-blue text-none plan-change'> Upgrade plan?</a></span> */}
              <p className='mb-0 text-gray2'>To Upgrade plan, contact <a href="mailto:support@linkvalidator.io">support@linkvalidator.io</a></p>
            </div>

          </Card>

        </div>
      </div>
  )
}

export default ProfileDetails;