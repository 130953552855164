import React, { useContext, useState, useEffect } from "react";
import "./progressBar.scss"

export const ProgressBar = (props) => {
    const [width, setWidth] = useState(1);
    useEffect(() => {
        const interval = setInterval(() => {
            if (width >= 100) {
                setWidth(1)
            } else {
                setWidth(width + (100 - width) / 8)
            }
        }, 300);

        return () => clearInterval(interval);
    }, [width]);

    return (
        <div className="progress-bar container">
            <div className="progress-bar fill" style={{ width: width + "%" }}></div>
        </div> || <></>
    );
}