import React, { useContext } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";


import Dashboard from "../views/Dashboard";
import Home from "../views/Home";
import Tutorials from "../views/tutorials";
import Analytics from "../views/Analytics";
import AnchorText from "../views/AnchorText";
import Issuses from "../views/Issues";
import Login from "../Components/auth/Login";
import { AppContext } from "../context/appContext";
import BreadCrumb from "../Components/BreadCrumbs/BreadCrumbs";
import ProfileDetails from "../Components/ProfileDetails";
import Register from "../Components/auth/Register";
import "../App.scss"

export default function AppRoutes() {

    const { appContext } = useContext(AppContext);
    return (
        <div className="routes">
            {appContext.showBreadCrumbs && appContext.isLoggedIn && <BreadCrumb />}
            <Routes>
                <Route index path={"/"} element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/Register" element={<Register />} />
                {appContext.isLoggedIn ? <>
                    <Route exact path={"/home"} element={<Home />} />
                    <Route exact path={"/:projectId/dashboard"} element={<Dashboard />} />
                    <Route exact path={"/:projectId/anchortext"} element={<AnchorText />} />
                    <Route exact path={"/:projectId/analytics"} element={<Analytics />} />
                    <Route exact path={"/:projectId/issues"} element={<Issuses />} />
                    <Route exact path="/pricing" element={""} />
                    <Route exact path="/profile" element={<ProfileDetails />} />
                    <Route exact path={"/tutorials"} element={<Tutorials />} />
                    <Route path="*" element={<Navigate to={"/home"} replace />} />
                </> :
                    <Route path="*" element={<Navigate to={"/login"} replace />} />
                }
            </Routes>
        </div>
    )
}