import { BASE_URL } from "../utils/Constants"
import { getAuthToken } from "../Components/commonHelper";

export async function projectSummary(username, company) {
    return fetch(
        `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/getsummary`,
        {
            method: "POST",
            headers: {
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ username: username, company_name: company }),
        }
    )
        .then((response) => {
            if (response.status == 401 || response.statusCode == 401) {
                // If token expried logout the user
                localStorage.clear();
                // window.location.href = '/login';
            }
            if (response.ok && response.status === 200) {
                return response.json();
            }
            throw new Error("Something went wrong in Analytics data");
        })
        .then(function (userData) {
            if (userData.statusCode === 200) {
                return userData.body;
            }
        })
};

export async function getUser(username) {
    return fetch(BASE_URL + `/TEST/getuser/sub/${username}`, {
        headers: {
            Authorization: getAuthToken(),
        },
    })
        .then((response) => {
            if (response.ok && response.status === 200) {
                return response.json();
            }
            // if (response.status == 401 || response.statusCode == 401 || response == undefined) {
            //     // If token expried logout the user
            //     localStorage.clear();
            //     window.location.href = '/login';
            // }
            throw new Error("Something went wrong in getting user user");
        })
        .then(function (userData) {
            if (userData.body.length > 0 && userData.statusCode === 200) {
                return userData.body
            }
        })
};

export async function addTokenSubsData() {
return fetch('https://backlinks.auth.us-east-1.amazoncognito.com/oauth2/token', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
        'grant_type': 'client_credentials',
        'client_id': 'jttmeu2uoptsbvnn47qnu1q8k',
        'client_secret': 'v99toch6m7h4hfdkhpc6rvkkdnem36aosm62japsv41n0tccu3q'
    })
})
.then(response => {
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
})
.catch(error => {
    console.error('There was a problem with the fetch operation:', error);
});
}


export async function addSubsData(f_name, l_name, email,businessname, token ) {
    

    return fetch('https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/addsubsdata', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token // Remove the extra "Authorization: " string
    },
    
    body: JSON.stringify({   username : email,
                fname: f_name,
                lname: l_name,
                type: "Free Plan",
                business_name: businessname,
                subscription_date: '',
                expiry_date: ''})
})
.then(response => {
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
})
.then(data => {
    console.log(data); // Handle the response data here
})
.catch(error => {
    console.error('There was a problem with the fetch operation:', error);
});



    // return fetch(BASE_URL + `/TEST/addsubsdata`, {
    //     method: "POST",
    //     headers: {
    //         Authorization: "Authorization: Bearer "  + token
            
    //     },
    //     body: JSON.stringify({
    //         username : email,
    //         fname: fname,
    //         lname: lname,
    //         type: "Free Plan",
    //         business_name: businessname,
    //         subscription_date: '',
    //         expiry_date: ''
    //     }),
    // })
    //     .then((response) => {
    //         if (response.ok && response.status === 200) {
    //             return response.json();
    //         }
    //         throw new Error("Something went wrong in getting user data");
    //     })
    //     .then(function (userData) {
    //         if (userData.body.length > 0 && userData.statusCode === 200) {
    //             return userData.body
    //         }
    //     })
};

export async function backlinkData(username, company) {
    return fetch(BASE_URL + `/TEST/getBacklinkWithUserName?username=${username}&company_name=${company}`, {
        headers: {
            Authorization: getAuthToken(),
        },
    })
        .then((response) => {
            if (response.ok && response.status === 200) {
                return response.json();
            }
            // if (response.status == 401 || response.statusCode == 401 || response == undefined) {
            //     // If token expried logout the user
            //     localStorage.clear();
            //     window.location.href = '/login';
            // }
            throw new Error("Something went wrong in getting user data");
        })
        .then(function (userData) {
            if (userData.body.length > 0 && userData.statusCode === 200) {
                return userData.body
            }
        })
};

export async function createBacklinks(list, company_name, createdBy, maxAllowedProjects, username) {
    return fetch(BASE_URL + "/TEST/backlinkcheckertest", {
        method: "POST",
        headers: {
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            start_urls: list,
            company_name: company_name,
            username: username,
            created_by: createdBy || "",
            subscription_type: maxAllowedProjects || 0,
        }),
    })
        .then((response) => {
            if (response.ok && response.status === 200) {
                return response.json();
            }
            throw new Error("Something went wrong in fetching back link data");
        })
        .then(function (data) {
            return data.body;
        })
};

export async function deleteBacklink(username, companyName, backlinks) {
    return fetch(BASE_URL + "/TEST/deletebacklink", {
        method: "POST",
        headers: {
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            username: username,
            companyname: companyName,
            backlinks: backlinks,
        }),
    })
        .then((response) => {
            if (response.ok && response.status === 200) {
                return response.json();
            }
            throw new Error("Something went wrong in deleting backlink data");
        })
};

export async function updateBacklink(data) {
    return fetch(BASE_URL + "/TEST/updatecost", {
        method: "POST",
        headers: {
            Authorization: getAuthToken(),
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok && response.status === 200) {
                return response.json();
            }
            throw new Error("Something went wrong in updating backlink data");
        })
};

export async function saveColPref(username, company, preference) {
    return fetch(BASE_URL + "/TEST/postPreference", {
        method: "POST",
        headers: {
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            username,
            companyname: company,
            preferences: preference
        }),
    })
        .then((response) => {
            if (response.ok && response.status === 200) {
                return response.json();
            }
            throw new Error("Something went wrong in updating preference data");
        })
};

export async function getColPref(username, company) {
    return fetch(BASE_URL + `/TEST/getPreference?username=${username}&companyname=${company}`, {
        headers: {
            Authorization: getAuthToken(),
        },
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            throw new Error("Something went wrong in getting user data");
        })
        .then((prefData) => {
            if (prefData.statusCode == 200 && "preferences" in prefData.body) {
                return prefData.body.preferences;
            } else {
                return {};
            }
        })
};