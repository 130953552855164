import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./views.scss";
import { AppContext } from "../context/appContext";
import { allCriticalIssues } from "../services/issuesService";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Components/Loader/Loader";
// import { data } from "../utils/issuesMock";
// import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
// import { DataGridPro } from "@mui/x-data-grid-pro";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Typography } from "@mui/material";
import { prepareIssueColumns } from "../utils/issueColumns";
const headerPrefix = "Links that are ";

export const Issues = (props) => {
    const { appContext, setAppContext } = useContext(AppContext);
    const { projectId } = useParams();
    // const [project, setProject] = useState(appContext.projectsList[projectId]);
    const [loading, setLoading] = useState(true);

    const [LNF, setLNF] = useState([]);
    const [LNI, setLNI] = useState([]);
    const [LANF, setLANF] = useState([]);
    const [LWF, setLWF] = useState([]);
    const [LMC, setLMC] = useState([]);
    const [LSP, setLSP] = useState([]);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        setAppContext({
            ...appContext,
            projectName: appContext.projectsList[projectId],
            projectId: projectId,
            pageName: "issues"
        });
        // setProject(() => appContext.projectsList[projectId])

        allCriticalIssues(appContext.user.username, appContext.projectsList[projectId])
            .then(data => {
                setLoading(false);
                setData(data);
            })
            .catch(err => navigate("/login"))
    }, [projectId]);

    return (
        loading ? <Loader></Loader> :
            data && <div
                className="view dashboard"
                style={{ padding: "1rem !important" }}>

                <Grid
                    container
                    gap={3}
                    columns={16}
                    justifyContent={"center"}
                    sx={{ height: "100%", overflow: "auto" }}
                >

                    {data.map(issue => {
                        return (
                            <Grid
                                item
                                xs={7}
                            >
                                <Typography fontWeight={"bold"} color={"gray"} padding={"1ch"}>{issue.title}</Typography>

                                <DataGrid
                                    slots={{
                                        noRowsOverlay: () => <div
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}><Typography>No issues here!</Typography></div>,
                                    }}
                                    sx={{ minHeight: "30rem", maxHeight: "30rem" }}
                                    density="compact"
                                    rows={issue.links.map((i, j) => ({ ...i, id: j }))}
                                    columns={prepareIssueColumns(Object.keys(issue.links[0] || {}).map(key => ({
                                        label: key == "link" ? issue.title :
                                            key == "provider" ? "Backlink Provider" :
                                                key == "notes" ? "Notes" :
                                                    key == "anchorText" ? "Anchor Text" :
                                                        key == "creation_date" ? "Creation Date" : key.toUpperCase(),
                                        name: key
                                    })))}
                                    rowHeight={38}
                                    disableRowSelectionOnClick
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 100,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={"null"}
                                />
                            </Grid>)
                    })}

                </Grid>
            </div> || <></>
    );
}

export default Issues