import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, } from '@mui/material';
import { useParams } from "react-router-dom";
import "./views.scss";
import { AppContext } from "../context/appContext";
import { backlinkData as getBacklinkData, deleteBacklink, saveColPref, getColPref, getUser } from "../services/dashboardService.js";
import { dashboardColumns, getAliasTextLinkType } from "../utils/dashboardColumns";
import { LicenseInfo } from '@mui/x-license-pro';
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Components/Loader/Loader";
import { EditDashboard } from "../Components/EditDashboard/EditDashboard";
import { IconDelete, IconRefresh, IconWriting, Preferences } from "../utils/Icon";
import { CreateProject } from "../Components/CreateProject/CreateProject";
LicenseInfo.setLicenseKey('5ddc2a00a6841d61d62411bb2397ad30Tz03NTg2NixFPTE3Mjc5NTQwNjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
const hiddenFields = ["Article Title", "Last Cached Date"];

const Dashboard = (props) => {
    const { appContext, setAppContext, reloadContext } = useContext(AppContext);
    const { projectId } = useParams();
    // const [project, setProject] = useState(appContext.projectsList[projectId]);

    const [backlinkData, setbacklinkData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditProject, setShowEditProject] = useState(null);
    const [projectData, setProjectData] = useState(null);
    const [showDetailsDialog, setShowDetailsDialog] = useState(false);
    const [detailsDialogData, setDetailsDialogData] = useState({});
    const [initialColPrefs, setInitialColPrefs] = useState({
        "Article Title": false,
        "Last Cached Date": false,
        "Cost": false,
        "Backlink Provider": false
    });

    const navigate = useNavigate();
    const [actions, setActions] = useState({});

    useEffect(() => {
        if (appContext.user.username) {
            getUser(appContext.user.username).then((response) => {
                if (response.length > 0 && (response[0].type == 1 || response[0].type == 2)) {
                    document.querySelector('.table').classList.add('show-limit-data');
                }
            });
        }
        setLoading(true);
        // setAppContext(() => ({
        //     ...appContext,
        //     // projectName: appContext.projectsList[projectId],
        //     // projectId: projectId,
        //     pageName: ""
        // }));
        setAppContext(() => ({
            ...appContext,
            projectName: appContext.projectsList[projectId],
            projectId: projectId,
            pageName: "Dashboard"
        }));

        getBacklinkData(appContext.user.username, appContext.projectsList[projectId])
            .then(data => {
                setbacklinkData(data);
                setLoading(false);
            })
            .catch(err => {
                if (err.status == 401) {
                    alert("Session Expired");
                }
                navigate("/login");
            });

        getColPref(appContext.user.username, appContext.projectsList[projectId])
            .then(data => {
                let colsToHide = data.map(col => ([col, false]));
                colsToHide = Object.fromEntries(colsToHide);
                setInitialColPrefs(colsToHide);
            })
            .catch(err => {
                setInitialColPrefs({
                    "Article Title": false,
                    "Last Cached Date": false,
                    "Cost": false,
                    "Backlink Provider": false
                });
            })


    }, [projectId]);

    useEffect(() => {
        if (backlinkData) {
            let a = {
                targetDomain: appContext.projectsList[projectId],
                backlinkProvider: appContext.userDetails?.backlinkProvider,
                backlinks: []//backlinkData.map(backlink => backlink.b_target_url)
            };
            setProjectData(a);
        }
    }, [backlinkData])

    useEffect(() => {
        if (showEditProject == false) {
            console.log("you should come here --> ", showEditProject);
            loadData();
        }
    }, [showEditProject])

    const clickHandler = (data = null, event = null) => {
        event?.stopPropagation();
        setActions(data);
        loadData();
    }

    function saveColumnPreference(prefs) {
        let colsToHide = Object.entries(prefs)
            .filter(row => row[1] == false)
        colsToHide = colsToHide.map(row => row[0]);

        saveColPref(appContext.user.username, appContext.projectsList[projectId], colsToHide)
            .then(data => console.log("preference saved"))
            .catch(err => console.log("failed to save preferences"));
    }

    function loadData() {
        setAppContext(() => ({ ...appContext, showProgressBar: true }));
        getBacklinkData(appContext.user.username, appContext.projectsList[projectId])
            .then(data => {
                setbacklinkData(data);
                setAppContext(() => ({ ...appContext, showProgressBar: false }));
                setLoading(false);
            })
            .catch(err => {
                if (err.status == 401) {
                    alert("Session Expired");
                }
                navigate("/login")
            });
    }

    function deleteHandler() {
        setLoading(true);
        setShowDeleteDialog(false);
        if (selectedRows.length == 0) {
            deleteBacklink(appContext.user.username, appContext.projectsList[projectId], null)
                .then(_ => {
                    reloadContext()
                        .then(data => {
                            setLoading(false);
                            let lastProject = (+projectId) > 0 ? data.projectsList[+projectId - 1] : "home";
                            let lastProjectId = (+projectId) > 0 ? +projectId - 1 : null;
                            let navigateTo = lastProjectId ? `/${lastProjectId}/dashboard` : `/home`;

                            setAppContext(() => ({
                                ...data,
                                projectName: lastProject,
                                projectId: lastProjectId,
                            }));
                            navigate(navigateTo);
                        })
                        .catch(err => {
                            setLoading(false);
                            navigate("/home");
                        });
                    return _;
                })
                .catch(_ => {
                    reloadContext()
                        .then(() => {
                            setLoading(false);
                            navigate("/home")
                        })
                        .catch(() => {
                            setLoading(false);
                            navigate("/home");
                        })
                })
        } else {
            let deleteData = selectedRows.map(rowId => backlinkData.filter(el => el.row_id == rowId)[0])
            Promise.all(deleteData.map(row => deleteBacklink(appContext.user.username, appContext.projectsList[projectId], row.a_source)))
                .then(_ => {
                    loadData();
                    setLoading(false);
                    setSelectedRows([])
                })
                .catch(_ => {
                    loadData();
                    setLoading(false);
                })
        }
    }

    const alisaDataTableData = (data) => {
        return data.map((item, i) => {
            return {
                "Date Added": item.creation_date,
                "Add Comments": item.a_source,
                "Referring Page": item.a_source,
                "Target URL Used": item.b_target_url,
                "Anchor Text": item.c_text,
                "Surrounding Text":
                    item.d_surround_text && item.d_surround_text.replace(/<[^>]+>/g, ""),
                "Link Type": item.e_rel_attr,
                "Sponsored signs": item.promoted,
                "Write For Us page": item.is_write_for_us,
                "External Links": item.external_link_count,
                "I.P Address": { country: item.country, ip: item.ip },
                "Country": item.country,
                "Article Title": item.title,
                "URL Indexed": item.index_status,
                "Link In Cached Version": item.link_exist,
                "Last Cached Date": item.cached_date,
                "Backlink Provider": item.created_by,
                "Cost": item.cost,
                "id": item.row_id,
                "meta": {
                    created_by: item.created_by,
                    notes: item.notes,
                    tags: item.tags,
                    id: i,
                    row: item.row_id,
                    backlink: item.a_source,
                    username: appContext.user.username,
                    company: appContext.projectsList[projectId],
                    cost: item.cost,
                    published_date: item.published_date,
                    expiry: item.expiry,
                }
            };
        });
    }


    //render functions
    function CustomToolBar() {
        return (
            <GridToolbarContainer className="toolbar">
                <div>
                    <GridToolbarColumnsButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </div>
                <div style={{ alignItems: "center" }}>
                    <Button
                        data="toolBtn"
                        sx={{ color: "#24B7C7" }}
                        endIcon={<IconWriting height={15} width={15} />}
                        onClick={setShowEditProject.bind(null, true)}
                    >Add Links</Button>
                    <Button
                        data="toolBtn"
                        className="delete-btn"
                        sx={{ bgcolor: "#ed5959", color: "white", "&:hover": { bgcolor: "darkred !important", color: "white !important" } }}
                        endIcon={<IconDelete height={15} width={15} />}
                        onClick={setShowDeleteDialog.bind(null, true)}
                    >{selectedRows.length > 0 ? `(${selectedRows.length})` : null}</Button>

                    <IconButton
                        onClick={loadData}>
                        <IconRefresh
                            color="#1976d2"
                        />
                        <Timer seconds={180} onEnd={loadData} timerShowCondition={10} />
                    </IconButton>
                </div>
            </GridToolbarContainer>
        )
    }

    function DeleteDialog() {
        return (
            <Dialog open={showDeleteDialog} onClose={setShowDeleteDialog.bind(null, false)}>
                <DialogTitle>Delete {selectedRows.length == 0 ? "Project" : "Links"}</DialogTitle>
                <DialogContent>
                    {
                        selectedRows.length == 0 ?
                            <>
                                <Typography>Are you sure you want to delete the project
                                    <Typography fontWeight={"bold"}>{appContext.projectsList[projectId]}?</Typography>
                                </Typography>
                                <Typography>This action cannot be undone!</Typography>
                            </>
                            :
                            <>
                                <Typography>Are you sure you want to delete the selected
                                    <Typography fontWeight={"bold"}>{selectedRows.length} link(s)?</Typography>
                                </Typography>
                                <Typography>This action cannot be undone!</Typography>
                            </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{ bgcolor: "red" }}
                        onClick={deleteHandler}>Confirm</Button>

                    <Button
                        onClick={setShowDeleteDialog.bind(null, false)}>Cancel</Button>
                </DialogActions>
            </Dialog>)
    }

    return (
        <div className="view dashboard">
            {loading && <Loader />}
            {showDeleteDialog && <DeleteDialog />}
            {showEditProject && projectData && <CreateProject
                isOpen={showEditProject}
                onClose={setShowEditProject}
                originalData={projectData}
                type="edit" />}

            <div className="table">
                {!backlinkData && !loading && <>
                    <Typography>Please add links to your project!</Typography>
                    <Button
                        data="toolBtn"
                        sx={{ bgcolor: "red", color: "white", "&:hover": { bgcolor: "darkred !important", color: "white !important" } }}
                        endIcon={<IconDelete height={15} width={15} />}
                        onClick={setShowDeleteDialog.bind(null, true)}
                    >Delete</Button>
                </>}
                {!loading && backlinkData && <DataGridPro
                    loading={loading}
                    onRowDoubleClick={(e, event) => { setShowDetailsDialog(true); setDetailsDialogData(e.row) }}
                    onRowSelectionModelChange={(selected) => setSelectedRows(selected)}
                    density="compact"
                    sx={{ overflowX: "scroll", padding: "5px" }}
                    rows={alisaDataTableData(backlinkData)}
                    columns={dashboardColumns(clickHandler)}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 100, page: 0 }
                        },
                        columns: {
                            columnVisibilityModel: initialColPrefs
                            // {
                            //     // Hide columns status and traderName, the other columns will remain visible
                            //     "Article Title": false,
                            //     "Last Cached Date": false,
                            //     "Cost": false,
                            //     "Backlink Provider": false
                            // },
                        }
                    }}
                    pageSizeOptions={[100, 200, 500, 1000]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onColumnVisibilityModelChange={(newModel) => saveColumnPreference(newModel)}
                    slots={{
                        toolbar: CustomToolBar.bind(null, loadData)
                    }}
                // slotProps={{
                //     columnsPanel: {
                //         getTogglableColumns,
                //     },
                // }}
                />}
            </div>
            {showDetailsDialog ? <Details
                data={detailsDialogData}
                onClose={setShowDetailsDialog}
                isOpen={showDetailsDialog} /> : null}
            {
                actions.edit ? <EditDashboard
                    modalState={actions.edit}
                    handleClose={clickHandler}
                    editData={actions.editData}
                /> :
                    actions.notes ? <Dialog open={true} onClose={setActions.bind(null, {})}><p>lets show notes here</p></Dialog> :
                        actions.user ? <Dialog open={true} onClose={setActions.bind(null, {})}><p>lets show something here</p></Dialog> :
                            actions.tag ? <Dialog open={true} onClose={setActions.bind(null, {})}><p>lets show tags here</p></Dialog> :
                                null
            }
        </div >
    );
}

export default Dashboard;


export function Timer({ hours, minutes, seconds, onEnd, timerShowCondition }) {
    const [hrs, setHrs] = useState(hours);
    const [mins, setMins] = useState(minutes);
    const [secs, setSecs] = useState(seconds);
    // enhance to cater for even hours and minutes
    useEffect(() => {
        const interval = setInterval(() => {
            if (secs <= 0) {
                onEnd();
            } else {
                setSecs(secs - 1)
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [secs]);

    return timerShowCondition >= secs &&
        <Tooltip
            title="refreshing data in ">
            <div
                style={{
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "1ch",
                    fontStyle: "oblique",
                    background: "rgba(106, 233, 247, 0.6)",
                    minWidth: "2.5ch",
                    minHeight: "2.5ch",
                    borderRadius: "50%"
                }}
            >{secs}</div>
        </Tooltip>
}


export function Details({ data, isOpen, onClose }) {
    const propertiesToShow = ["date added", "referring page",
        "target url used", "anchor text",
        "surrounding text", "link type",
        "sponsored signs", "write for us page",
        "external links", "i.p address", "country", "article title", "url indexed", "link in cached version",
        "last cached date", "backlink provider", "cost"];
    return (
        <Dialog open={isOpen}>
            <DialogTitle>Details</DialogTitle>
            <DialogContent>
                {
                    Object.entries(data)
                        .filter(([key, value]) => propertiesToShow.includes(key.toLowerCase()))
                        .map(([key, value], i) => {
                            return value && <Box key={i}>
                                <Typography fontWeight={"bold"}>{key}</Typography>
                                <Typography>{
                                    key == "Link Type" ? Object.values(getAliasTextLinkType(value)).join(", ") :
                                        typeof (value) == "string" ?
                                            value.includes("http") ? <a href={value} target="_blank">{value}</a> :
                                                value :
                                            Object.values(value).join(", ")}
                                </Typography>
                                <br />
                            </Box>
                        })
                }
            </DialogContent>
            <Button
                onClick={onClose.bind(null, false)}>Close</Button>
        </Dialog>
    )
}