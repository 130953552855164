import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useState } from "react";
import UserPool from "../../UserPool";
import { addUserDetails } from "../commonHelper";
import BgImage from "../../assets/images/loginBg.svg";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import profile from "../../assets/images/avtarSignup.svg";
import emailLogo from "../../assets/images/email.svg";
import { useSearchParams } from 'react-router-dom';
import { addSubsData, addTokenSubsData } from "../../services/dashboardService";
import { useEffect } from "react";
import { CrossIcon, IconQuotes } from "../../utils/Icon";

function Register() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [renterPassword, setRenterPassword] = useState("");
  const [verifyProcess, setVerifyProcess] = useState(false);
  // const [resend, setResend] = useState(false);
  const [OTP, setOTP] = useState("");
  const [fname, setFirstName] = useState("");
  const [lname, setLastName] = useState("");
  const [businessname, setBusinessName] = useState("");
  const [searchParams] = useSearchParams();
  const [hidden, setHidden] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const regex = /<a\s[^>]*>([^<]+)<\/a>/gi;
    let match = regex.exec(searchParams.get('customer_email'))
    setFirstName(searchParams.get('first_name'));
    setLastName(searchParams.get('last_name'));
    if (match) {
      setUsername(match[1]);
      setEmail(match[1]);
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const attributeList = [];
    if (password !== renterPassword) {
      alert("Password didn't match");
      return;
    }
    attributeList.push(
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      })
    );
    UserPool.signUp(username, password, attributeList, null, (err, data) => {
      if (err) {
        console.log(err);
        var errIntext = err.toString();
        console.log(data);
        if (errIntext.includes("InvalidPasswordException")) {
          alert(
            "Password length should be minimum 8 character with numbers, special character, uppercase letters & lowercase letters. example: gaBriel@12"
          );
        }
        if (errIntext.includes("UsernameExistsException")) {
          alert("User Already Exists, please login");
        }
      } else {
        addUserDetails(username);
        getToken();
        setVerifyProcess(true);
        // alert("Register Successfully");
      }
    });
  };

  const getToken = () => {
    addTokenSubsData()
    .then(data => {
      addUserDataApi(data?.access_token || '')
    })
    .catch(err => console.log("error", err));
  }

  const addUserDataApi = (token) => {
    if(token != ''){
      addSubsData(fname, lname, email, businessname, token )
      .then(data => console.log(data))
      .catch(err => navigate("/login"));
    }
    else{
      console.log("toekn not found")
    }
  }


  const verifyAccount = (e) => {
    e.preventDefault();
    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    console.log(user);
    user.confirmRegistration(OTP, true, (err, data) => {
      if (err) {
        console.log(err);
        // setResend(true);
        alert("Please enter valid OTP");
      } else {
        console.log(data);
        alert("Account verified successfully");
        window.location.href = "/login";
      }
    });
  };

  return (
    <div className="register">
      <div className="content" style={{ backgroundImage: `url(${BgImage})` }}>
        <div className="logo">
          <img src={logo} alt="" />
          <span>Link Validator</span>
        </div>
        <div className="content-details">
          <span className="icon-quote">
            <IconQuotes />
          </span>
          <h2>
            We’ve invested a lot of resources in building links for our clients and we don’t want to spend more time checking if there are any link drops or glitches in those links. Link Validator keeps track of all backlinks with a click of a button.
          </h2>
          <div className="review-details">
            <img src={profile} alt="" />
            <div className="review-name">
              <p className="name">Alex Shute</p>
              <span className="desig">CEO, <a href="https://hummingbrd.co/" target='_blank'>Hummingbrd SEO</a></span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-register">
        {verifyProcess === false ? (
          <form onSubmit={onSubmit} className="mx-auto">
            <h2 className="form-heading">Create account</h2>
            <div className="row gx-2">
              {/* <div className="name-wrap form-row"> */}
              <div className="col-6">
                <div className="input-group">
                  <label>First name</label>
                  <input type="text" value={fname} placeholder="First name" onChange={(e) => setFirstName(e.target
                    .value)} />
                </div>
              </div>
              <div className="col-6">
                <div className="input-group">
                  <label>Last name</label>
                  <input type="text" value={lname} placeholder="Last name" onChange={(e) => setLastName(e.target
                    .value)} />
                </div>
              </div>
            </div>
            {/* </div> */}

            <div className="input-group">
              <label>Email</label>
              <input
                type="email"
                value={email.toLowerCase().trim()}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setUsername(e.target.value);
                }}
              />
            </div>

            <div className="input-group">
              <label>Business name</label>
              <input type="text"
                value={businessname}
                placeholder="Your company name"
                onChange={(e) => setBusinessName(e.target.value)} />
            </div>
            <div className="input-group">
              <label>Password</label>
              {hidden ?
                <>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <i class="fa fa-eye-slash" aria-hidden="true" onClick={(e) => setHidden(false)}></i>
                </> :
                <>
                  <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
                  <i class="fa fa-eye" aria-hidden="true" onClick={(e) => setHidden(true)}></i>
                </>
              }
            </div>
            <div className="input-group">
              <label>Re-enter Password</label>
              <input
                type="password"
                value={renterPassword}
                onChange={(e) => setRenterPassword(e.target.value)}
              />
            </div>
            {/* <br /> */}
            <p className="privacy-policy">
              <input type="checkbox" value={'check'} />You agree to our friendly &nbsp;<a href="https://linkvalidator.io/privacy-policy/" target="_blank"> privacy policy.</a>
            </p>
            <button type="submit" className="mb-5">Create a account</button>
            <p className="signup-line mb-5">
              <span>
                {" "}
                Already have an account?<a href="/login">Login</a>
              </span>
            </p>
          </form>
        ) : (
          <div className="modal-container">
            <div className="modal-custom modal">
              <div className="modal-header">
                {/* <span className="close-button"><CrossIcon /></span> */}
              </div>
              <div className="email-confirmation mx-auto">
                <div className="email-logo">
                  <img src={emailLogo} alt="Email" />
                </div>
                <h2 className=" h-2 mb-5 text-center">Email Confirmation</h2>
                <p className="email-message text-center">
                  We have sent a 6 digit code to {username}. Please verify your
                  email address to go further. Follow the steps shown in the email
                </p>
                <form onSubmit={verifyAccount} className="mx-auto">
                  <div className="input-group">
                    <input className="mx-auto"
                      type="text"
                      value={OTP}
                      onChange={(e) => setOTP(e.target.value)}
                    />
                  </div>
                  <p className="not-rescived-otp text-center">
                    Didn’t receive an email? <a href="#">Resent</a>
                  </p>
                  <button className="mx-auto mb-5" type="submit">Next</button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Register;
