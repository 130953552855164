import React, { useContext, useEffect } from "react";
import { AppContext } from "../context/appContext";

export const Tutorials = (props) => {
    const { appContext, setAppContext } = useContext(AppContext);
    useEffect(() => {
        setAppContext({
            ...appContext,
            pageName: "tutorials",
            projectName: null
        });
      }, []);
    return (
        <div className="view tutorials">
            <h1 className="page-heading">Get Started</h1>
            <div className="col-6">
            <div className="analytics-card  bg-white card-table">
              {/* <h5 className="analytics-card-heading">Backlinks by countries</h5> */}
              <div className="mt-4">
                <table className="analytics-table">
                  <thead>
                    <tr>
                      <th>Videos</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                        <tr>
                          <td><img src="./validator-img.png" alt="" /></td>
                          <td><a href="https://www.loom.com/share/cb55d823846346ce8cf5aa6eb656cc20" target="_blank">How to create a new project and add data.</a></td>
                          <td>03 min</td>
                        </tr>
                        <tr>
                        <td><img src="./validator-img.png" alt="" /></td>
                        <td><a href="https://www.loom.com/share/f9f8ec359dde4c73be1d5ad63f2d8ff6" target="_blank"> Link Validator Dashboard</a></td>
                          <td>04:50 min</td>
                        </tr>
                        <tr>
                        <td><img src="./validator-img.png" alt="" /></td>
                        <td><a href="https://www.loom.com/share/1e5e0ae6f65c46fabc5b9c758b1c8bd9" target="_blank">Critical Issues & Analytics</a></td>
                          <td>02:17 min</td>
                        </tr>

                  </tbody>
                </table>
              </div>
            </div>
            </div>
        </div>
    )
}

export default Tutorials;