import React, { useContext, useEffect, useState } from "react";
import "./views.scss";
import { AppContext } from "../context/appContext";
import { Card, CardActions, CardContent, Typography, Button, Zoom, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconCriticalIssues, IconAnalytic, IconAnchorText, IconDashboard } from "../utils/Icon";
import "../stylesheet/global.scss"

const Home = (props) => {
    const { appContext, setAppContext } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        setAppContext({
            ...appContext,
            pageName: "home",
            projectName: null
        })

    }, [])

    return (<>
        <Typography
            sx={{ fontSize: "2ch", fontWeight: "bolder", margin: "0 2ch" }}>Registered Domains</Typography>
        <div className="flex-row">
            <div
                className="width-100 app-background"
                style={{
                    height: "100%",
                    overflow: "auto",
                    padding: "0 15px",
                }}
            >
                {appContext.projectsList.length ? appContext.projectsList.map((proj, i) => {
                    return (
                        <Card
                            className="width-100"
                            sx={{ margin: "0.5rem 0" }}
                        >
                            <CardContent>
                                <Typography
                                    sx={{ fontSize: "16px", fontWeight: "bolder" }}>{proj}</Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    sx={{ fontSize: "12px"}}
                                    variant="contanied"
                                    startIcon={<IconDashboard />}
                                    onClick={navigate.bind(null, `/${appContext.projectsList.indexOf(proj)}/dashboard`)}>Dashboard</Button>

                                <Button
                                    sx={{ fontSize: "12px"}}
                                    variant="contanied"
                                    startIcon={<IconAnchorText color={"black"} />}
                                    onClick={navigate.bind(null, `/${appContext.projectsList.indexOf(proj)}/anchortext`)}>Anchor Text Profiles</Button>

                                <Button
                                    sx={{ fontSize: "12px"}}
                                    variant="contanied"
                                    startIcon={<IconAnalytic color={"gray"} />}
                                    onClick={navigate.bind(null, `/${appContext.projectsList.indexOf(proj)}/analytics`)}>Analytics</Button>

                                <Button
                                    sx={{ fontSize: "12px"}}
                                    variant="contanied"
                                    startIcon={<IconCriticalIssues color="red" />}
                                    onClick={navigate.bind(null, `/${appContext.projectsList.indexOf(proj)}/issues`)}>Critical Issues</Button>
                            </CardActions>
                        </Card>
                    )
                })
                    :
                    <p>No projects registered yet, please create a project using "Create Project" button</p>}
            </div>
            {/* <div className="width-40 flex-column">
              
            </div> */}
        </div></>
    );
}

export default Home;