import { getAuthToken } from "../Components/commonHelper";

export const allCriticalIssues = (username, project) => {
    return fetch(
        `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/getcriticalissues`,
        {
            method: "POST",
            headers: {
                Authorization: getAuthToken(),
                // 'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
                username: username,
                company_name: project
            }),
        }
    )
        .then((response) => {
            if (response.ok && response.status === 200) {
                return response.json();
            }
            // throw new Error("Something went wrong in updating Anchor text value");
        })
        .then(function (userData) {
            if (userData.statusCode === 200) {
                return userData.body;
            }
        })
};