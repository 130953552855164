import React, { useContext } from "react";
import { AppContext } from "../../context/appContext";
import { Breadcrumbs, Typography } from "@mui/material";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import "./BreadCrumbs.scss"

export default function BreadCrumb() {
    const { appContext } = useContext(AppContext);
    return (
        appContext.showBreadCrumbs && <Breadcrumbs
            className="bread-crumbs">
            {appContext.showProgressBar && <ProgressBar />}
            
            {(appContext.pageName.toLowerCase() !== "home" && appContext.pageName.toLowerCase() !== "tutorials") ? (
    <Typography>{appContext.projectName}</Typography>
) : null}


            <Typography
                textTransform={"capitalize"}>{appContext.pageName}
            </Typography>
        </Breadcrumbs>
    )
}