import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, TextareaAutosize, FormControl, InputLabel } from "@mui/material";
import "./editDashboard.scss";
import Logo from "../../assets/images/logo.svg";
import { updateBacklink } from "../../services/dashboardService";
import loader from '../../assets/images/loader-black.gif';

export const EditDashboard = ({ modalState, handleClose, editData = {} }) => {
    const [formData, setFormData] = useState(editData);
    const [loading, setLoading] = useState(false);


    function onSubmit(e) {
        setLoading(true);
        e.preventDefault();
        updateBacklink(formData)
            .then(_ => { setLoading(false); handleClose({ edit: false }) })
            .catch(_ => { setLoading(false); handleClose({ edit: false }) })
    }


    return (
        <Dialog
            open={modalState}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth
        >
            {loading && <div
                className="loader-custom"
                style={{
                    height: "5rem",
                    width: "5rem",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    zIndex: 2,
                    transform: "translate(-50%, -50%)",
                    backdropFilter: "blur(4px)"
                }}
            ><img src={loader} alt="loading..." /></div>}
            <form
                onSubmit={onSubmit}>
                <DialogTitle
                    className="flex-row"
                    sx={{ fontSize: "larger", textAlign: "center", padding: "2rem 1rem", justifyContent: "center" }}
                >
                    <img src={Logo} alt="" />
                    <h3>Edit Details</h3>
                </DialogTitle>
                <DialogContent sx={{
                    padding: "5rem 3rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem"
                }}>
                    <>
                        <InputLabel
                            htmlFor="cost"
                            sx={{ fontSize: "small" }}>Cost</InputLabel>
                        <FormControl
                            fullWidth
                            hiddenLabel>
                            <TextField
                                autoFocus
                                margin="none"
                                id="cost"
                                labelId="cost"
                                type="text"
                                fullWidth
                                value={formData.cost}
                                variant="outlined"
                                onChange={e => setFormData({
                                    ...formData,
                                    cost: e.target.value
                                })}
                            />
                        </FormControl>
                    </>

                    <>
                        <InputLabel
                            htmlFor="notes"
                            sx={{ fontSize: "small" }}>Notes</InputLabel>
                        <FormControl fullWidth>
                            <TextareaAutosize
                                margin="none"
                                minRows={5}
                                maxRows={30}
                                // style={{ minWidth: "100%", margin: "0.5rem 0" }}
                                id="notes"
                                placeholder="Notes"
                                value={formData.notes}
                                onChange={e => setFormData({
                                    ...formData,
                                    notes: e.target.value
                                })}
                            />
                        </FormControl>
                    </>

                    <>
                        <InputLabel
                            htmlFor="link"
                            sx={{ fontSize: "small" }}>Link Published By</InputLabel>
                        <FormControl fullWidth>
                            <TextField
                                margin="none"
                                id="link"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={formData.created_by}
                                onChange={e => setFormData({
                                    ...formData,
                                    created_by: e.target.value
                                })}
                            />
                        </FormControl>
                    </>

                    {/* <>
                        <InputLabel
                            htmlFor="expiryDate"
                            sx={{ fontSize: "small" }}>Expiring On</InputLabel>
                        <FormControl fullWidth>
                            <TextField
                                margin="none"
                                id="expiryDate"
                                // label="Expiring On"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={formData.expiry}
                                onChange={e => setFormData({
                                    ...formData,
                                    expiry: e.target.value
                                })}
                            />
                        </FormControl>
                    </> */}

                    {/* <>
                        <InputLabel
                            htmlFor="publishDate"
                            sx={{ fontSize: "small" }}>Published Date</InputLabel>
                        <FormControl fullWidth>

                            <TextField
                                margin="none"
                                id="publishDate"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={formData.published_date}
                                onChange={e => setFormData({
                                    ...formData,
                                    published_date: e.target.value
                                })}
                            />
                        </FormControl>
                    </> */}

                </DialogContent>
                <DialogActions sx={{ padding: "1rem" }}>
                    <Button onClick={() => handleClose({ edit: false })} >Cancel</Button>
                    <Button
                        sx={{ bgcolor: "#24B7C7", color: "white", "&:hover": { bgcolor: "#0F172A" } }}
                        type="submit"
                    >Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}