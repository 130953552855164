import React, { useContext, useEffect, useMemo, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import loader from '../assets/images/loader.gif';
import { getAnalyticsData, getAnalyticsSummaryData } from "../Components/commonHelper";
import "../Components/analytics/Analytics.scss";
import { AppContext } from "../context/appContext";
import { useParams, useNavigate } from "react-router-dom";


export default function Analytics(props) {
    const { appContext, setAppContext } = useContext(AppContext);
    const { projectId } = useParams();
    // const [project, setProject] = useState(appContext.projectsList[projectId]);
    const [anchorText, setAnchorText] = useState({});
    const [countries, setCountries] = useState([]);
    const [indexing, setIndexing] = useState({});
    const [link_type, setLinkType] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setAppContext({
            ...appContext,
            projectName: appContext.projectsList[projectId],
            projectId: projectId,
            pageName: "Analytics"
        });
        // setProject(() => appContext.projectsList[projectId])
        setLoading(true);
        getAnalyticsSummaryData(appContext.user.username, appContext.projectsList[projectId]);
        getAnalyticsData(appContext.user.username, appContext.projectsList[projectId])
            .then((data) => {
                setCountries(data.Countries);
                setIndexing(data.Indexing);
                setLinkType(data.LinkType);
                setAnchorText(data.AnchorText);
                setLoading(false);
            })
            .catch(err => navigate("/login"));
    }, [projectId]);


    const data = {
        labels: ["Nofollow", "Dofollow", "Sponsored", "Link Not Found"],
        datasets: [
            {
                label: "",
                data: [link_type.Nofollow, link_type.Dofollow, link_type.Sponsored, link_type.LinkNotFound],
                borderColor: ["rgba(255,206,86,0.2)"],
                backgroundColor: ["#24B7C7", "#8FD9E1", "#CFEDF0"],
                pointBackgroundColor: "rgba(255,206,86,0.2)",
            },
        ],
    };
    const data1 = {
        labels: ["Indexed", "Non Indexed"],
        datasets: [
            {
                label: "",
                data: [indexing.Indexed, indexing.NonIndexed],
                borderColor: ["rgba(255,206,86,0.2)"],
                backgroundColor: ["#24B7C7", "#8FD9E1"],
                pointBackgroundColor: "rgba(255,206,86,0.2)",
            },
        ],
    };
    const data2 = {
        labels: ["Branded", "URL", "Exact Match", "Miscellaneous", "Partial Match", "Images", "Un-categorized"],
        datasets: [
            {
                label: "",
                data: [anchorText.Branded, anchorText.URL, anchorText.ExactMatch, anchorText.Miscellaneous, anchorText.PartialMatch, anchorText.Images, anchorText['Un-categorized']],
                borderColor: ["rgba(255,206,86,0.2)"],
                backgroundColor: ["#24B7C7", "#A155B9", "#34D2A3", "#F26797", "#FFCBCB", "#DBB1EA", "#f5e642"],
                pointBackgroundColor: "rgba(255,206,86,0.2)",
            },
        ],
    };
    const plugins = [
        {
            beforeDraw: function (chart) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                // ctx.restore();
                // var fontSize = 50;
                ctx.font = `bolder 32px 'Inter', sans-serif`;
                ctx.textAlign = "right";
                ctx.textBaseline = "middle";
                var text = `600`;
                // ctx.fillText(text, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);
                ctx.fillText(text, Math.round((width - ctx.measureText(text).width) / 2 + 14), height / 2);
                ctx.restore();
                ctx.display = "block";
                ctx.font = `12px 'Inter', sans-serif`;
                ctx.textBaseline = "middle";
                var text1 = 'Total Links';
                ctx.fillText('Total Links', Math.round((width - ctx.measureText(text1).width) / 2 + 10), height / 2 + 25);

                ctx.restore();
            },
        },
    ];
    const options = {
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: "right",
            align: "start",
            labels: {
                // generateLabels: (chart) => {
                //   const datasets = chart.data.datasets;
                //   return datasets[0].data.map((data, i) => ({
                //     text: `${chart.data.labels[i]} ${data}`,
                //     fillStyle: datasets[0].backgroundColor[i],
                //     index: i
                //   }))
                // },
                usePointStyle: true,
            },
        },
        plugins: {
            title: {
                display: true,
                text: 'Custom Chart Title',
                padding: {
                    top: 60,
                    bottom: 30
                }
            },
        },
    };
    return (
        loading ? <div className="loader-custom"><img src={loader} alt="loading..." /></div>
            :
            <div className="flex width-100">
                <div className="row gx-1">
                    <div className="col-6 pb-5">
                        <div className="analytics-card  bg-white">
                            <h5 className="analytics-card-heading">Link Type</h5>
                            <p className="total-count">Total Links: {link_type.total} </p>
                            <div
                                className="link-type"
                                id="link-type"
                            >
                                <Doughnut
                                    data={data}
                                    options={options}
                                    // plugins={plugins}
                                    width={270}
                                    height={270}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 pb-5">
                        <div className="analytics-card  bg-white">
                            <h5 className="analytics-card-heading">Indexing</h5>
                            <p className="total-count">Total Links: {indexing.total} </p>
                            <div
                                className="indexing"
                                id="link-type"
                            >
                                <Doughnut
                                    data={data1}
                                    options={options}
                                    // plugins={plugins}
                                    width={270}
                                    height={270}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Table design */}
                    <div className="col-6">
                        <div className="analytics-card  bg-white">
                            <h5 className="analytics-card-heading">Backlinks by countries</h5>
                            <div className="mt-4">
                                <table className="analytics-table">
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Country</th>
                                            <th>Ratio</th>
                                            <th>Backlinks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            countries && countries.map((item, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.country}</td>
                                                    <td>{Math.round(item.ratio)}</td>
                                                    <td>{item.count}</td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="analytics-card bg-white">
                            <h5 className="analytics-card-heading">Anchor text</h5>
                            <p className="total-count">Total Links: {anchorText.total} </p>
                            <div
                                className="anchor-text"
                                id="link-type"
                            >
                                <Doughnut
                                    data={data2}
                                    options={options}
                                    // plugins={plugins}
                                    width={274}
                                    height={274}
                                />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
    )
}
