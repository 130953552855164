import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { YesBadge, CrossIcon, IconBlocked, IconClose, IconStickNotes, IconUserOutline, IconWriting, Shield } from "../utils/Icon";
import { CRAWL_BLOCKED, CRAWL_BLOCKED_TIP, NOT_GOOD_TARGET_URLS, ERROR_TEXTS } from "./Constants";
const UPDATING = "updating...";

export const getAliasTextLinkType = (linkTypeText) => {
    let text = linkTypeText;
    let tip = "";
    switch (linkTypeText?.toLowerCase()) {
        case "sponsored":
            text = "Sponsored";
            tip = "Sponsered";
            break;
        case "nofollow":
            text = "NF";
            tip = "No Follow";
            break;
        case "page not accessible":
            tip = "Page Not Accessible";
            text = "PNA"
            break;
        case "link not found":
        case "page not found":
        case "site error":
            // text = <span style={{ color: "#ed5959" }}>{ERROR_TEXTS[linkTypeText.split(" ").map(word => word[0]).join("")]}</span>;
            text = "hi";
            tip = linkTypeText;
            break;
        case "page crawling blocked, please check manually":
            text = "Page Crawling Blocked, Please Check Manually";
            tip = "Page Crawling Blocked, Please Check Manually";
            break;
        default:
            if (text == null) {
                text = "DF";
                tip = "Do Follow"
            }
            if (text.includes("nofollow")) {
                text = "NF";
                tip = "No Follow"
            } else if (text.includes("sponsored")) {
                text = "Sponsored";
                tip = "Sponsered"
            } else {
                text = "DF";
                tip = "Do Follow"
            }
            break;
    }
    return { label: text, tooltip: tip };
};

export const dashboardColumns = (clickHandler) => ([
    {
        headerName: "Date Added",
        field: "Date Added",
        width: 100,
        headerClassName: "table-header-background",
        align: "center",
        justify: "center",
    },
    {
        headerName: "Referring Page",
        field: "Referring Page",
        width: 300,
        align: "left",
        headerClassName: "table-header-background",
        renderCell: ({ value }) => value == CRAWL_BLOCKED ?
            <Tooltip title={CRAWL_BLOCKED_TIP}>
                <div>
                    <Shield />
                </div>
            </Tooltip> :
            Object.keys(ERROR_TEXTS).includes(value?.toLowerCase()) ?
                <Tooltip title={value}>
                    <div style={{ color: "red" }}>{ERROR_TEXTS[value?.toLowerCase()]}</div>
                </Tooltip >
                :
                value?.toLowerCase() == UPDATING ?
                    <Tooltip title={"The data will be updated within 24 hours."}>
                        <div>{value}</div>
                    </Tooltip > :
                    <Tooltip title={value}>
                        <a href={value} target="_blank">{value}</a>
                    </Tooltip>
    },
    {
        headerName: "Target URL Used",
        field: "Target URL Used",
        width: 250,
        headerClassName: "table-header-background",
        align: "left",
        renderCell: ({ value }) => value == CRAWL_BLOCKED ?
            <Tooltip title={CRAWL_BLOCKED_TIP}><div><Shield /></div></Tooltip> :
            Object.keys(ERROR_TEXTS).includes(value?.toLowerCase()) ?
                <Tooltip title={value}>
                    <div style={{ color: "red" }}>{ERROR_TEXTS[value?.toLowerCase()]}</div>
                </Tooltip>
                :
                value?.toLowerCase() == UPDATING ?
                    <Tooltip title={"The data will be updated within 24 hours."}>
                        <div>{value}</div>
                    </Tooltip > :

<Tooltip title={value}>
    <a
        style={{ color: (value && value.toLowerCase().startsWith("http://")) ? "red" : (NOT_GOOD_TARGET_URLS.includes(value?.toLowerCase()) ? "red" : "#24B7C7") }}
        href={value}
        target="_blank"
    >
        {value}
    </a>
</Tooltip>



                  
    },
    {
        headerName: "Anchor Text",
        field: "Anchor Text",
        width: 150,
        headerClassName: "table-header-background",
        align: "left",
        justify: "center",
        renderCell: ({ value }) => value == CRAWL_BLOCKED ?
            <Tooltip title={CRAWL_BLOCKED_TIP}><div><Shield /></div></Tooltip> :
            Object.keys(ERROR_TEXTS).includes(value?.toLowerCase()) ?
                <Tooltip title={value}>
                    <div style={{ color: "red" }}>{ERROR_TEXTS[value?.toLowerCase()]}</div>
                </Tooltip>
                :
                value?.toLowerCase() == UPDATING ?
                    <Tooltip title={"The data will be updated within 24 hours."}>
                        <div>{value}</div>
                    </Tooltip > :
                    <Tooltip title={value}><div>{value}</div></Tooltip>
    },
    {
        headerName: "Surrounding Text",
        field: "Surrounding Text",
        width: 250,
        headerClassName: "table-header-background",
        align: "left",
        justify: "center",
        renderCell: ({ value }) => value == CRAWL_BLOCKED ?
            <Tooltip title={CRAWL_BLOCKED_TIP}><div><Shield /></div></Tooltip> :
            Object.keys(ERROR_TEXTS).includes(value?.toLowerCase()) ?
                <Tooltip title={value}>
                    <div style={{ color: "red" }}>
                        {ERROR_TEXTS[value?.toLowerCase()]}
                    </div>
                </Tooltip>
                :
                value?.toLowerCase() == UPDATING ?
                    <Tooltip title={"The data will be updated within 24 hours."}>
                        <div>{value}</div>
                    </Tooltip > :
                    <Tooltip title={value} >{value}</Tooltip>
    },
    {
        headerName: "Link Type",
        headerAlign: "center",
        field: "Link Type",
        width: 80,
        headerClassName: "table-header-background",
        align: "center",
        justify: "center",
        renderCell: ({ value }) => value == CRAWL_BLOCKED ?
            <Tooltip title={CRAWL_BLOCKED_TIP}><div><Shield /></div></Tooltip> :
            Object.keys(ERROR_TEXTS).includes(value?.toLowerCase()) ?
                <Tooltip title={value}>
                    <div style={{ color: "red" }}>
                        {ERROR_TEXTS[value?.toLowerCase()]}
                    </div>
                </Tooltip>
                :
                value?.toLowerCase() == UPDATING ?
                    <Tooltip title={"The data will be updated within 24 hours."}>
                        <div>{value}</div>
                    </Tooltip > :
                    <Tooltip
                        title={getAliasTextLinkType(value).tooltip || "NA"}>
                        {getAliasTextLinkType(value).label}
                    </Tooltip>
    },
    {
        headerName: "Sponsored signs",
        headerAlign: "center",
        field: "Sponsored signs",
        width: 80,
        headerClassName: "table-header-background",
        // align: "center",
        justify: "center",
        renderCell: ({ value }) => value == CRAWL_BLOCKED ?
            <Tooltip title={CRAWL_BLOCKED_TIP}><div><Shield /></div></Tooltip>
            : value?.toLowerCase() == 'yes' ? <Tooltip title={value}> <div style={{ color: "red" }}> Yes</div> </Tooltip>
                : value?.toLowerCase() == 'no' ? <Tooltip title={"No"}> <div>No</div> </Tooltip> :
                    value?.toLowerCase() == 'updating...' ? <Tooltip title={"Will be updated in next few hurs"}><div>Updating...</div> </Tooltip> :
                        Object.keys(ERROR_TEXTS).includes(value?.toLowerCase()) ? <Tooltip title={value}>
                            <div style={{ color: "red" }}>
                                {ERROR_TEXTS[value?.toLowerCase()]}
                            </div>
                        </Tooltip>
                            :
                            value?.toLowerCase() == UPDATING ?
                                <Tooltip title={"The data will be updated within 24 hours."}>
                                    <div>{value}</div>
                                </Tooltip > :
                                value

    },
    {
        headerName: "Write For Us page",
        headerAlign: "center",
        field: "Write For Us page",
        width: 100,
        // align: "center",
        headerClassName: "table-header-background",
        justify: "center",
        renderCell: ({ value }) => value == CRAWL_BLOCKED ?
            <Tooltip title={CRAWL_BLOCKED_TIP}><div><Shield /></div></Tooltip> :
            value?.toLowerCase() == "yes" ? <Tooltip title={value}>
                <div style={{ color: "red" }}>
                    {"yes"}
                </div>
            </Tooltip> :
                value?.toLowerCase() == "updating..." ?
                    <Tooltip title={"Will be updated in next few hours"} ><div>Updating</div></Tooltip> :
                    Object.keys(ERROR_TEXTS).includes(value?.toLowerCase()) ? <Tooltip title={value}>
                        <div style={{ color: "red" }}>
                            {ERROR_TEXTS[value?.toLowerCase()]}
                        </div>
                    </Tooltip>
                        :
                        value?.toLowerCase() == UPDATING ?
                            <Tooltip title={"The data will be updated within 24 hours."}>
                                <div>{value}</div>
                            </Tooltip > :
                            <Tooltip title={value} ><div>{value}</div></Tooltip>
    },
    {
        headerName: "External Links",
        headerAlign: "center",
        field: "External Links",
        width: 100,
        headerClassName: "table-header-background",
        align: "center",
        justify: "center",
        renderCell: ({ value }) => value == CRAWL_BLOCKED ?
            <Tooltip title={CRAWL_BLOCKED_TIP}><div><Shield /></div></Tooltip> :
            value.length > 5 ? <Tooltip title={value}>
                <div><IconClose height={"30"} width={"30"} color={"red"} /></div>
            </Tooltip> :
                value
    },
    {
        headerName: "Country",
        headerAlign: "center",
        field: "I.P Address",
        width: 80,
        headerClassName: "table-header-background",
        align: "center",
        justify: "center",
        renderCell: ({ value }) => <Tooltip title={<span>{value.ip}  <br /> {value.country}</span>}><div><ReactCountryFlag countryCode={value.country.toLowerCase()} /></div></Tooltip>
    },
    {
        headerName: "Article Title",
        headerAlign: "center",
        field: "Article Title",
        width: 250,
        headerClassName: "table-header-background",
        align: "left",
        justify: "center",
        // renderCell: ({ value }) => <Tooltip title={value}>{value}</Tooltip>
    },

    {
        headerName: "URL Indexed",
        headerAlign: "center",
        field: "URL Indexed",
        width: 150,
        headerClassName: "table-header-background",
        align: "center",
        justify: "center",
        renderCell: ({ value }) => value?.toLowerCase() == "yes" ?
            <Tooltip title={value}>
                <div>
                    <YesBadge />
                </div>
            </Tooltip>
            :
            value?.toLowerCase() == "no" ?
                <Tooltip title={value}><div style={{ color: "red" }}>No</div></Tooltip> :
                value?.toLowerCase() == UPDATING ?
                    <Tooltip title={"The data will be updated within 24 hours."}>
                        <div>{value}</div>
                    </Tooltip > :
                    value
    },
    {
        headerName: "Link In Cached Version",
        headerAlign: "center",
        field: "Link In Cached Version",
        width: 150,
        headerClassName: "table-header-background",
        align: "center",
        justify: "center",
        renderCell: ({ value }) => value == "true" ?
            <Tooltip title={value}>
                <div>
                    <YesBadge />
                </div>
            </Tooltip>
            :
            value?.toLowerCase() == UPDATING ?
                <Tooltip title={"The data will be updated within 24 hours."}>
                    <div>{value}</div>
                </Tooltip > :
                value == "false" ?
                    <Tooltip title={value} style={{ color: "red" }}><div>No</div></Tooltip> :
                    value

    },
    {
        headerName: "Last Cached Date",
        headerAlign: "center",
        field: "Last Cached Date",
        width: 150,
        headerClassName: "table-header-background",
        align: "left",
        justify: "center",
    },
    {
        headerName: "Cost",
        headerAlign: "center",
        field: "Cost",
        width: 80,
        headerClassName: "table-header-background",
        align: "left",
        justify: "center",
        renderCell: ({ value }) => [undefined, null, ""].includes(value) ? "-" : value
    },
    {
        headerName: "Backlink Provider",
        headerAlign: "center",
        field: "Backlink Provider",
        width: 80,
        headerClassName: "table-header-background",
        align: "left",
        justify: "center",
        renderCell: ({ value }) => [undefined, null, ""].includes(value) ? "-" : <Tooltip title={value}><div>{value}</div></Tooltip>
    },
    {
        headerName: "Actions",
        headerAlign: "center",
        field: "meta",
        width: 200,
        headerClassName: "table-header-background",
        renderCell: ({ value }) => <Box
            sx={{
                // margin: "5px",
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "baseline"
            }}>
            {/* <IconButton onClick={clickHandler.bind(null, { tag: true })}><Icontag color={"gray"} /></IconButton> */}
            <Tooltip title={value.notes || "No Notes added yet"}><div><IconStickNotes color={"gray"} /></div></Tooltip>
            { /* <Tooltip title={value.created_by || "Publisher"}><div><IconUserOutline color={"gray"} /></div></Tooltip> */}
            <IconButton onClick={clickHandler.bind(this, { edit: true, editData: value })}><IconWriting color={"gray"} height={15} width={15} /></IconButton>
        </Box>
    }
])
