import { Tooltip } from "@mui/material"

export function prepareIssueColumns(columnNames) {
    return columnNames.map(column => ({
        headerName: column.label,
        field: column.name,
        flex: 1,
        headerAlign: column.name == "provider" ? "center" : "left",
        align: column.name == "provider" ? "center" : "left",
        renderCell: ({ value }) => (value.includes("http") ?
            <Tooltip title={value}><a href={value} target="_blank">{value}</a></Tooltip> :
            <Tooltip title={value}>{value}</Tooltip>)
    }))
}

export const linksNotFound = () => ([
    {
        headerName: "Links",
        field: "link",
        flex: 1,
        headerAlign: "start",
        // resizable: true,
        renderCell: ({ value }) => <a href={value} target="_blank">{value}</a>
    },
    {
        headerName: "Provider",
        field: "provider",
        flex: 1,
        headerAlign: "start",
        align: "end",
        // resizable: true,
    },]
)

export function linksNotIndexed() {
    return (
        [
            {
                headerName: "Links",
                field: "link",
                flex: 1,
                headerAlign: "start",
                // resizable: true,
                renderCell: ({ value }) => <a href={value} target="_blank">{value}</a>
            },
            {
                headerName: "Provider",
                field: "provider",
                minWidth: 250,
                headerAlign: "center",
                align: "center",
                // resizable: true,
            }]
    )
}

export function linksAsNofollow() {
    return ([
        {
            headerName: "Links",
            field: "link",
            flex: 1,
            headerAlign: "start",
            // resizable: true,
            renderCell: ({ value }) => <a href={value} target="_blank">{value}</a>
        },
        {
            headerName: "Provider",
            field: "provider",
            minWidth: 250,
            headerAlign: "center",
            align: "end",
            // resizable: true,
        }])
}


export function LinksWithWriteForUs() {
    return ([
        {
            headerName: "Links",
            field: "link",
            flex: 1,
            headerAlign: "start",
            // resizable: true,
            renderCell: ({ value }) => <a href={value} target="_blank">{value}</a>
        },
        {
            headerName: "Provider",
            field: "provider",
            minWidth: 250,
            headerAlign: "center",
            align: "end",
            // resizable: true,
        }])
}


export function LinksWithMissingCache() {
    return ([
        {
            headerName: "Links",
            field: "link",
            flex: 1,
            headerAlign: "start",
            align: "end",
            // resizable: true,
            renderCell: ({ value }) => <a href={value} target="_blank">{value}</a>
        },
        {
            headerName: "Provider",
            field: "provider",
            minWidth: 250,
            headerAlign: "center",
            // resizable: true,
        }])
}

export function LinksWithSponsoredPost() {
    return ([
        {
            headerName: "Links",
            field: "link",
            flex: 1,
            headerAlign: "start",
            // resizable: true,
            renderCell: ({ value }) => <a href={value} target="_blank">{value}</a>
        },
        {
            headerName: "Provider",
            field: "provider",
            minWidth: 250,
            headerAlign: "center",
            align: "end",
            // resizable: true,
        }])
}
