import React, { createContext, useContext, useState } from "react";
import { getUserDetails, getAllSearchedTargetDomain } from "../Components/commonHelper";
import UserPool from "../UserPool";
// import { Navigate, useNavigate } from "react-router-dom";

export const AppContext = createContext();
export const defaultAppcontext = {
    pageName: "home",
    userDetails: {},
    user: {},
    projectName: null,
    projectsList: [],
    dashboardData: [],
    analyticsData: [],
    criticalIssuesData: [],
    showSideBar: false,
    showBreadCrumbs: true,
    reloadSidebar: false,
    reloadDashboard: false,
    reloadAnalytics: false,
    reloadIssues: false,
    isLoggedIn: false,
    authToken: null,
    projectId: null,
    showProgressBar: false
}

export default function Context(props) {
    const Roles = {
        ADMIN: "ADMIN",
        USER: "USER",
    };
    let contextData = localStorage.getItem("context");

    const [appContext, setAppContext] = useState(() => contextData ? {
        ...JSON.parse(contextData),
        projectsList: JSON.parse(contextData).projectsList || [],
        authToken: localStorage.getItem("AUTH_TOKEN")
    } : defaultAppcontext);


    async function reloadContext() {
        localStorage.removeItem("context");
        let userDetails = await getUserDetails(appContext.user.username);
        let projectsList = await getAllSearchedTargetDomain(appContext.user.username);

        const context = {
            ...appContext,
            userDetails: userDetails.body[0],
            projectsList: projectsList,
        };

        if (projectsList.length == 0) {
            context.pageName = "home";
        }

        setAppContext(() => {
            try {
                localStorage.setItem("context", JSON.stringify(context));
            } catch (error) {
            }
            return context;
        });

        return context;
    }

    return <AppContext.Provider value={{ appContext, setAppContext, reloadContext }}>
        {props.children}
    </AppContext.Provider>
}