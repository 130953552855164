
import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import "./views.scss";
import { AppContext } from "../context/appContext";
import { backlinkData as getBacklinkData } from "../services/dashboardService.js";
import { analyticsColumns } from "../utils/analyticsColumns";
import { Loader } from "../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { getAnalyticsData } from "../Components/commonHelper";
import { Doughnut } from "react-chartjs-2";
import { updateAnchorTextType } from "../Components/commonHelper";
import "../stylesheet/global.scss";
import { COLOR_CODES } from "../utils/Constants";
import { Box, Typography } from "@mui/material";
import { LicenseInfo } from '@mui/x-license-pro';
import { DataGridPro } from "@mui/x-data-grid-pro";
LicenseInfo.setLicenseKey('5ddc2a00a6841d61d62411bb2397ad30Tz03NTg2NixFPTE3Mjc5NTQwNjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export const AnchorText = (props) => {
    const { appContext, setAppContext } = useContext(AppContext);
    const { projectId } = useParams();
    // const [project, setProject] = useState(appContext.projectsList[projectId]);
    const [backlinkData, setbacklinkData] = useState(null);
    const [anchorText, setAnchorText] = useState([]);
    const [isDataUpdated, setIsDataUpdated] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const labels = ["Branded", "URL", "Exact Match", "Miscellaneous", "Partial Match", "Images", "Un Categorized"]

    useEffect(() => {
        setAppContext({
            ...appContext,
            projectName: appContext.projectsList[projectId],
            projectId: projectId,
            pageName: "Anchor Text Profiles"
        });
        // setProject(() => appContext.projectsList[projectId])

        getBacklinkData(appContext.user.username, appContext.projectsList[projectId])
            .then(data => setbacklinkData(alisaDataTableData(data)))
            .catch(err => navigate("/login"));


        getAnalyticsData(appContext.user.username, appContext.projectsList[projectId])
            .then((data) => {
                const anchorTextData = {
                    labels: ["Branded", "URL", "Exact Match", "Miscellaneous", "Partial Match", "Images", "Un-categorized"],
                    datasets: [
                        {
                            label: "",
                            data: [data.AnchorText.Branded, data.AnchorText.URL, data.AnchorText.ExactMatch, data.AnchorText.Miscellaneous, data.AnchorText.PartialMatch, data.AnchorText.Images, data.AnchorText['Un-categorized']],
                            backgroundColor: ["#24B7C7", "#A155B9", "#34D2A3", "#F26797", "#FFCBCB", "#DBB1EA", "#f5e642"],
                        },
                    ],
                };
                setAnchorText(anchorTextData);
                setLoading(false);
            })
            .catch(err => navigate("/login"));

    }, [projectId, isDataUpdated]);

    const alisaDataTableData = (data) => {
        return data.map((item, i) => {
            return {
                "Add Comments": item.a_source,
                "Referring Page": item.a_source,
                "Target URL Used": item.b_target_url,
                "Anchor Text": item.c_text,
                "Anchor Text Type": item.anchor_text_type,
                "Backlink Provider": item.created_by,
                data: { id: i, anchorText: item.anchor_text_type },
                "id": i
            };
        });
    }

    function handleChange(value, id) {
        setLoading(true);
        //console.log(appContext.projectName);
        let item = backlinkData.filter(item => item.data.id == id)[0];
        updateAnchorTextType(item["Referring Page"], appContext.user.username, value,appContext.projectName)
            .then(data => {
                setIsDataUpdated(data);
                setLoading(false);
            })
            .catch(err => navigate("/login"));
    }

    function CustomToolBar() {
        return (
            <GridToolbarContainer className="toolbar">
                <div>
                    {/* <GridToolbarColumnsButton /> */}
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </div>
            </GridToolbarContainer>
        )
    }


    return (
        <div className="view analytics">
            {!backlinkData && <Loader />}

            <div className="table width-75">
                {backlinkData && <DataGridPro
                    loading={loading}
                    sx={{
                        overflowX: "scroll", padding: "5px"
                    }}
                    rows={backlinkData}
                    density="compact"
                    columns={analyticsColumns(handleChange)}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                paginationModel: { pageSize: 100 }
                            },
                        },
                    }}
                    pagination
                    pageSizeOptions={[100, 200, 500, 1000]}
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: CustomToolBar.bind(null)
                    }}
                />}
            </div>

            {anchorText.datasets && <div className="width-40 flex">
                <Box
                    sx={{
                        height: "350px",
                        width: "350px",
                        position: "absolute",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <Doughnut
                        data={anchorText}
                        redraw={false}
                        width={350}
                        height={350}
                    />
                    <Box style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.5em",
                        marginBlock: "1em"
                    }}>
                        {anchorText["datasets"][0].data.map((e, i) => (
                            <div style={{
                                display: "flex",
                                gap: "5px",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "flex-start"
                            }}>
                                <div style={{
                                    width: "0.5em",
                                    height: "0.5em",
                                    background: COLOR_CODES[labels[i]],
                                    borderRadius: "50%"
                                }}></div>
                                <p style={{
                                    padding: "0",
                                    margin: "0",
                                    fontSize: "0.825em",
                                    color: "darkslategray"
                                }}>{labels[i]} : {e}</p>
                            </div>))}
                    </Box>
                </Box>
            </div>}
            {/* <pre>{JSON.stringify(backlinkData, null, 2)}</pre> */}
        </div>
    );
}

export default AnchorText