import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Account } from "./Components/auth/Account";
import Context from "./context/appContext";
import SideBar from "./Components/SideBar/SideBar";
import AppRoutes from "./routes/routes";

import "./App.scss";

function App() {
  return (
    <div className="app">
      <Context>
        <Account>
          <Router>
            <SideBar />
            <AppRoutes />
          </Router >
        </Account>
      </Context>
    </div>
  );
}

export default App;
