import React, { useContext, useEffect, useRef, useState } from "react";
import Logo from "../../assets/images/logo.svg";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, FormControl, TextareaAutosize } from "@mui/material";
import { createBacklinks } from "../../services/dashboardService";
import { AppContext } from "../../context/appContext";
import loaderBlack from "../../assets/images/loader-black.gif";

import "../../stylesheet/global.scss";
import { Navigate, useNavigate } from "react-router-dom";

export function CreateProject({
    type = "create",
    originalData = {},
    onClose = () => { },
    isOpen = false }) {

    const [formData, setFormData] = useState(originalData);
    const [loading, setLoading] = useState(false);
    const [createError, setCreateError] = useState(null);
    const myRef = useRef("");
    const { appContext, reloadContext, setAppContext } = useContext(AppContext);
    const navigate = useNavigate();


    function onSubmit(e) {
        setLoading(true);
        e.preventDefault();
        createBacklinks(
            myRef.current.value.split("\n").join("|"),
            type.toLowerCase() == "create" ? formData.targetDomain : appContext.projectName,
            formData.backlinkProvider,
            appContext.userDetails.type,
            appContext.user.username)
            .then(data => {
                if (data == "Please upgrade your plan!") {
                    setCreateError(data);
                } else {
                    reloadContext()
                        .then(data => {
                            setLoading(false);
                            let goToProject = data.projectsList[data.projectsList.length - 1];
                            let goToProjectId = data.projectsList.length - 1;
                            let navigateTo = `/${goToProjectId}/dashboard`;

                            setAppContext({
                                ...data,
                                projectName: goToProject,
                                projectId: goToProjectId,
                            });
                            navigate(navigateTo);
                            onClose(false);
                        })
                        .catch(err => {
                            setLoading(false);
                            navigate("/home")
                            onClose(false);
                        });
                }
            })
            .catch((err) => {
                reloadContext()
            })

    }

    function onCancel() {
        onClose(false);
    }


    return (
        <Dialog
            open={isOpen}
            className="backdrop"
            fullWidth
            maxWidth="md"
        >
            {/* <pre>{JSON.stringify(originalData, null, 2)}</pre> */}

            {createError ? <DialogContent>
                <div
                    className="flex center">
                    {createError}
                    <Button onClick={onCancel}>OK</Button>
                </div>
            </DialogContent>
                :
                <form onSubmit={onSubmit}>
                    <DialogTitle className="flex start" sx={{ alignItems: "center" }}>
                        <img src={Logo} height={50} width={50} />
                        {type.toLowerCase() == "create" ?
                            <Typography><h2>Create Project</h2></Typography>
                            :
                            <Typography><h2>Edit Project - {appContext.projectName}</h2></Typography>}
                    </DialogTitle>

                    <DialogContent>
                        <div className="flex width-100">

                            <FormControl
                                fullWidth>
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    required="required"
                                    label="Target Domain"
                                    placeholder="(without https/www/http)"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={formData.targetDomain}
                                    disabled={type == "create" ? false : true}
                                    onChange={(event) => { setFormData({ ...formData, targetDomain: event.target.value }) }}
                                />
                            </FormControl>

                            <FormControl
                                fullWidth>
                                <TextField
                                    margin="normal"
                                    label="Backlink Provider"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={formData.backlinkProvider}
                                    onChange={(event) => { setFormData({ ...formData, backlinkProvider: event.target.value }) }}
                                />
                            </FormControl>
                        </div>

                        <div className="flex">
                            <FormControl
                                fullWidth>
                                <label>Backlinks</label>
                                <TextareaAutosize
                                    margin="normal"
                                    minRows={5}
                                    maxRows={30}
                                    style={{ minWidth: "100%", margin: "1rem 0", whiteSpace: "pre-line" }}
                                    id="notes"
                                    label="Backlinks"
                                    required="required"
                                    placeholder="{ URL 1 } &#10;{ URL 2 } &#10;{ URL 3 } "
                                    // onChange={(event) => {
                                    //     setFormData({
                                    //         ...formData,
                                    //         backlinks: event.target.value
                                    //     })
                                    // }}
                                    ref={myRef}
                                    fullWidth
                                />
                            </FormControl>

                        </div>


                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="submit"
                            sx={{ bgcolor: "#24B7C7", color: "white", "&:hover": { bgcolor: "#13374A" } }}>
                            {type == "create" ? "Create" : "Update"}</Button>
                        <Button onClick={onCancel}>Cancel</Button>
                        {loading && <div className="loader">
                            <img src={loaderBlack} height={50} width={50} />
                        </div>}
                    </DialogActions>
                </form>
            }
        </Dialog>
    );
}
