import { Box, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { anchorTestTypes } from "./anchorTextTypes";

export const analyticsColumns = (handleChange) => ([
    {
        headerName: "Referring Page",
        field: "Referring Page",
        minWidth: 250,
        headerAlign: "center",
        // resizable: true,
        renderCell: ({ value }) => <a href={value} target="_blank">{value}</a>
    },
    {
        headerName: "Target URL Used",
        field: "Target URL Used",
        minWidth: 250,
        headerAlign: "center",
        // resizable: true,
        renderCell: ({ value }) => value === "Page Crawling Blocked, Please Check Manually" ? value : <a href={value} target="_blank">{value}</a>
    },
    // {
    //     headerName: "Anchor Text Type",
    //     field: "Anchor Text Type",
    //     minWidth: 250,
    //     headerAlign: "center",
    //     resizable: true,
    //     renderCell: ({ value }) => <div >{value}</div>
    // },
    {
        headerName: "Anchor Text",
        field: "Anchor Text",
        minWidth: 200,
        headerAlign: "center",
        // resizable: true,
    },
    {
        headerName: "Anchor Text Type",
        field: "data",
        minWidth: 150,
        headerAlign: "center",
        // resizable: true,
        renderCell: ({ value }) => <FormControl
            fullWidth
            size="small">
            {/* <InputLabel
                id="select">
                {parseInt(anchorTestTypes.filter(el => el.label == value.anchorText)[0]?.value)}</InputLabel> */}
            <Select
                labelId="select"
                id="test"
                value={anchorTestTypes.filter(el => el.label == value.anchorText)[0]?.value || 7}
                onChange={(e) =>
                    handleChange(anchorTestTypes.filter(el => el.value == e.target.value)[0]["label"], value.id)
                }>
                {anchorTestTypes.map(type => <MenuItem
                    value={type.value}
                    sx={{ bgcolor: type.color }}>
                    {type.label}</MenuItem>)}
            </Select>
        </FormControl>
    },
    {
        headerName: "Anchor Text Type Indicator",
        field: "Anchor Text Type",
        minWidth: 20,
        headerAlign: "center",
        resizable: true,
        renderCell: ({ value }) => <FormControl fullWidth>
            <Tooltip title={value}>
                <div
                    style={{ background: anchorTestTypes.filter(val => val.label.toLowerCase() == value?.toLowerCase())[0]?.color || anchorTestTypes[6].color }}>
                    &nbsp;
                </div>
            </Tooltip>
        </FormControl>
    },
])

