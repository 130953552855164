import { BASE_URL } from "../utils/Constants";

export const getAuthToken = () => {
  return `Bearer ${localStorage.getItem("AUTH_TOKEN")}`;
};

export const getUserDetails = (username) => {
  return fetch(
    `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/getuser/sub/` + username,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    }
  )
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => {
      console.log(err);
    });
};

export const getAllUnprocessdLinks = (username) => {
  return fetch(
    `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/getbacklinksunprocessed`,
    {
      headers: {
        Authorization: getAuthToken(),
      },
    }
  )
    .then((response) => response.json())
    .then((data) => data.body)
    .catch((err) => {
      console.log(err);
    });
};

export const getUnprocessedData = (userId) => {
  return fetch(
    `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/getbacklinkwithuid/?` +
    new URLSearchParams({
      uid: userId,
    }),
    {
      headers: {
        Authorization: getAuthToken(),
      },
    }
  )
    .then((response) => response.json())
    .then((data) => data.body)
    .catch((err) => {
      console.log(err);
    });
};

export const getAnalyticsSummaryData = (username, company) => {
  return fetch(
    `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/getsummary`,
    {
      method: "POST",
      headers: {
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({ username: username, company_name: company }),
    }
  )
    .then((response) => {
      if (response.status == 401 || response.statusCode == 401) {
        // If token expried logout the user
        localStorage.clear();
        // window.location.href = '/login';
      }
      if (response.ok && response.status === 200) {
        return response.json();
      }
      throw new Error("Something went wrong in Analytics data");
    })
    .then(function (userData) {
      if (userData.statusCode === 200) {
        return userData.body;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateAnchorTextType = (backlink, username, anchor_text_type, company_name) => {
  //console.log(company_name, "company_name")
  return fetch(
    `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/updateanchortexttype`,
    {
      method: "POST",
      headers: {
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({ backlink: backlink, username: username, anchor_text_type: anchor_text_type, company_name: company_name }),
    }
  )
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      }
      throw new Error("Something went wrong in updating Anchor text value");
    })
    .then(function (userData) {
      if (userData.statusCode === 200) {
        return userData.body;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// for comments
export const updateNotes = (backlink, username, notes) => {
  return fetch(
    `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/updatenotes`,
    {
      method: "POST",
      headers: {
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({ backlink: backlink, username: username, notes: notes }),
    }
  )
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      }
      throw new Error("Something went wrong in updating Comment");
    })
    .then(function (userData) {
      if (userData.statusCode === 200) {
        return userData.body;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

// for cost
export const updateCost = (backlink, username, cost) => {
  return fetch(
    `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/updatecost`,
    {
      method: "POST",
      headers: {
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({ backlink: backlink, username: username, cost: cost }),
    }
  )
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      }
      throw new Error("Something went wrong in updating Cost");
    })
    .then(function (userData) {
      if (userData.statusCode === 200) {
        return userData.body;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};


export const getAnalyticsData = (username, company) => {
  return fetch(
    `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/getanalyticsdata`,
    {
      method: "POST",
      headers: {
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({ username: username, company_name: company }),
    }
  )
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      }
      throw new Error("Something went wrong in Analytics data");
    })
    .then(function (userData) {
      if (userData.statusCode === 200) {
        return userData.body;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addUserDetails = (user) => {
  return fetch(
    `https://ji4sss2zvj.execute-api.us-east-1.amazonaws.com/TEST/users`,
    {
      method: "PUT",
      headers: {
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({ username: user }),
    }
  );
};

export const updateUserDetails = (data) => {
  return fetch(
    `https://gnur5vtyce.execute-api.us-east-1.amazonaws.com/TEST/updatesubsdata`,
    {
      method: "PUT",
      headers: {
        Authorization: getAuthToken(),
      },
      body: JSON.stringify(data),
    }
  );
};


export const getAllSearchedTargetDomain = (username) => {
  return fetch(BASE_URL + `/TEST/gettargetdomains?username=${username}`, {
    headers: { Authorization: getAuthToken() },
  })
    .then(resp => resp.json())
    .then(data => data.body)
    .catch(err => err);
};
